import {Chip} from "@mui/material";
import {IStatusLabelProps} from "./props";

export const StatusLabel = ({label, sx, className, color}: IStatusLabelProps) => {
    return (
        <Chip
            sx={{
                ...sx && sx,
            }}
            {...className && {className: className}}
            label={label}
            color={color}
            variant="filled"
        />
    );
}
