import {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "../redux/hooks/useRedux";
import {toastSelector} from "../redux/toast/selectors";
import {hideToast, showToast} from "../redux/toast/toastSlice";
import {ToastEnum} from "../types";


export const useToast = () => {
    
    const {show, type, message} = useAppSelector(toastSelector);
    
    const dispatch = useAppDispatch()
    
    const toast = useCallback((message: string, type = ToastEnum.SUCCESS) => {
        dispatch(showToast({
                message,
                type,
            }
        ))
    }, [dispatch])
    
    
    const closeToast = useCallback(() => {
        dispatch(hideToast())
    }, [dispatch])
    
    return {
        show,
        type,
        message,
        toast,
        closeToast,
    }
}
