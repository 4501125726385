import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";


export const ArchivedConversation = () => {
    return (
        <>
            
            <Box
                sx={{
                    width: 'calc(100% - 0)',
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: '0 auto',
                    backgroundColor: 'error.main',
                    padding: '1rem',
                }}
            >
                <Typography
                    variant={'h6'}
                    color={'#fff'}
                >
                    Cette conversation est marquée comme archivée !
                </Typography>
            </Box>
        </>
    )
}
