import {zodResolver} from "@hookform/resolvers/zod";
import {NavigateNext} from "@mui/icons-material";
import {TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useToast} from "../../../hooks";
import useError from "../../../hooks/useError";
import CrudForm from "../../../lib/components/Form/CrudForm";
import {ToastEnum} from "../../../types";
import {PhoneLoginFormSchema, PhoneLoginFormType} from "../schemas";
import {useAskForPasswordlessLoginMutation} from "../services/authApi";


interface IPhoneLoginFormProps {
    open: boolean
    onClose: () => void
    onSuccessfulOtpSent: (phone_number: string) => void
}


export const PhoneLoginForm = ({open, onClose, onSuccessfulOtpSent}: IPhoneLoginFormProps) => {
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
    const [
        askForPasswordlessLogin, {
            isLoading: isSubmitting,
            isError,
            error: loginError,
            isSuccess,
            data
        }
    ] = useAskForPasswordlessLoginMutation();
    const {register, handleSubmit, formState, reset, control} = useForm<PhoneLoginFormType>({
        resolver: zodResolver(PhoneLoginFormSchema)
    });
    const {errors} = formState;
    const {setErrors, getError, hasError} = useError(errors);
    const {toast} = useToast()

    useEffect(() => {
        setErrors(errors)
    }, [errors, setErrors])

    useEffect(() => {
        if (isSuccess && phoneNumber) {
            toast("Un message contenant votre de connexion à usage unique est envoyé au numéro que vous avez saisi. Veuillez saisir le code reçu dans le formulaire suivant !")
            onSuccessfulOtpSent(phoneNumber)
            onClose()
        }

        if (isError) {
            setErrors((
                loginError as any
            ).data.violations ?? {})
            toast("Impossible d'envoyer votre code d'authentification à usage unique, merci de vérifier votre numéro et réessayer", ToastEnum.ERROR)
        }
    }, [phoneNumber, isSuccess, isError, loginError, setErrors, onSuccessfulOtpSent, onClose, toast])

    const onFormSubmit = (data: PhoneLoginFormType) => {
        setPhoneNumber(data.phone_number)
        askForPasswordlessLogin({
            data: data
        })
    }

    return (
        <>
            <CrudForm
                open={open}
                onClose={onClose}
                title={'Veuillez saisir votre numéro de téléphone'}
                isSubmitting={isSubmitting}
                isEdition={false}
                addButtonIcon={<NavigateNext/>}
                addButtonLabel={'Envoyer le code'}
                onSubmit={handleSubmit(onFormSubmit)}
            >
                <TextField
                    disabled={false}
                    label="Numéro de téléphone"
                    variant="outlined"
                    fullWidth
                    type={'tel'}
                    margin="normal"
                    inputProps={register('phone_number')}
                    error={hasError('phone_number') || hasError('rate_limit')}
                    helperText={getError('phone_number') || getError('rate_limit') || 'Veuillez resaisir votre numéro de téléphone avec le préfixe international (ex: +226)'}
                />
            </CrudForm>
        </>
    )
}
