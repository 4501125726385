import {configureStore} from "@reduxjs/toolkit";
import authReducer from "../../modules/auth/redux/features/authSlice";
import {authApi} from "../../modules/auth/services/authApi";
import {profileApi} from "../../modules/auth/services/profileApi";
import {brigadeApi} from "../../modules/brigades/services/brigadeApi";
import {conversationApi} from "../../modules/chatbot/services/conversationApi";
import {conversationRequestApi} from "../../modules/chatbot/services/conversationRequestApi";
import {messageApi} from "../../modules/chatbot/services/messageApi";
import {rubricApi} from "../../modules/chatbot/services/rubricApi";
import {flashApi} from "../../modules/flashes/services/flashApi";
import chatReducer from "../conversation/chatSlice";
import conversationReducer from "../conversation/conversationSlice";
import httpErrorReducer from "../features/http-errors/httpErrorSlice";
import toastReducer from "../toast/toastSlice";
import {ratingApi} from "../../modules/chatbot/services/ratingApi";


export const store = configureStore({
    reducer: {
        [conversationRequestApi.reducerPath]: conversationRequestApi.reducer,
        [brigadeApi.reducerPath]: brigadeApi.reducer,
        [flashApi.reducerPath]: flashApi.reducer,
        [messageApi.reducerPath]: messageApi.reducer,
        [rubricApi.reducerPath]: rubricApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [conversationApi.reducerPath]: conversationApi.reducer,
        [ratingApi.reducerPath]: ratingApi.reducer,
        auth: authReducer,
        chat: chatReducer,
        toast: toastReducer,
        httpError: httpErrorReducer,
        conversation: conversationReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({})
        .concat([
            conversationRequestApi.middleware,
            brigadeApi.middleware,
            flashApi.middleware,
            messageApi.middleware,
            rubricApi.middleware,
            authApi.middleware,
            profileApi.middleware,
            conversationApi.middleware,
            ratingApi.middleware,
        ]),
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
