import {useContext} from "react";
import {PrivateChatBodyContext} from "../../../context/PrivateChatBodyContext";

export const usePrivateChatBody = () => {

    const context = useContext(PrivateChatBodyContext);

    if (!context) {
            throw new Error('usePrivateChatBody must be used within a PrivateChatBodyContext');
    }

    return context;
}
