import {ArrowRight} from "@mui/icons-material";
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {styled} from "@mui/material/styles";
import React, {useCallback, useEffect, useState} from "react";
import {useMenuBreakPoints} from "../../../../hooks";
import {WhiteButton} from "../../../../lib/components/Button";
import {PasswordlessLoginForm} from "../../../auth/components/PasswordlessLoginForm";
import {PhoneLoginForm} from "../../../auth/components/PhoneLoginForm";
import {useAuth} from "../../../auth/hooks/useAuth";
import {usePrivateChat} from "../../hooks";
import {AskConversationForm} from "../AskConversationForm";
import {ContinueConversationForm} from "../ContinueConversationForm";
import {IChatBotActionsFormsState, IChatBotCTAProps} from "./props";
import {storage} from "../../../../utils";


styled(Button)(({theme}) => (
    {
        margin: theme.spacing(1),
    }
));
export const ChatBotCTA = ({}: IChatBotCTAProps) => {
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
    const {conversationsList} = usePrivateChat()
    const {user: auth} = useAuth()
    const [openAdvanceForms, setOpenAdvanceForms] = useState<IChatBotActionsFormsState>({
        openConversationForm: false,
        openRememberMeForm: false,
        openContinueConversationForm: false,
        openPasswordlessForm: false,
        openPhoneLoginForm: false,
    });
    const {
        openConversationForm,
        openContinueConversationForm,
        openPasswordlessForm,
        openPhoneLoginForm
    } = openAdvanceForms;
    const {tablet} = useMenuBreakPoints()

    useEffect(() => {
        if (auth && storage.exists('new_chat')) {
            setOpenAdvanceForms(prevState => (
                {
                    ...prevState,
                    openConversationForm: true
                }
            ))
        }
    }, [auth]);

    const handleSuccessfullSubmitSubject = useCallback(() => {
        // check if user is authenticated
        if (auth) {
            storage.forget('new_chat')
        }
    }, [auth]);

    const handleOpenActionForm = useCallback((action: keyof IChatBotActionsFormsState) => {
        setOpenAdvanceForms(prevState => (
            {
                ...prevState,
                [action]: true
            }
        ))
    }, [])

    const handleCloseActionForm = useCallback((action: keyof IChatBotActionsFormsState) => {
        setOpenAdvanceForms(prevState => (
            {
                ...prevState,
                [action]: false
            }
        ))
    }, [])

    const closeOpenConversationForm = useCallback(() => {
        handleCloseActionForm('openConversationForm')
    }, [handleCloseActionForm])

    const closeOpenContinueConversationForm = useCallback(() => {
        handleCloseActionForm('openContinueConversationForm')
    }, [handleCloseActionForm])

    const closeOpenPasswordlessForm = useCallback(() => {
        handleCloseActionForm('openPasswordlessForm')
    }, [handleCloseActionForm])

    const closeOpenPhoneLoginForm = useCallback(() => {
        handleCloseActionForm('openPhoneLoginForm')
    }, [handleCloseActionForm])

    const handleOnOTPSuccess = useCallback((phone_number: string) => {
        setPhoneNumber(phone_number)
        handleOpenActionForm('openPasswordlessForm')
    }, [handleOpenActionForm])

    const handleOnLoginSuccess = useCallback(() => {
        window.location.href = '/';
    }, [])

    const hasActiveConversation = useCallback(
        () => conversationsList.some(conversation => !conversation.is_closed),
        [conversationsList]
    )

    return (
        <>
            <Box
                sx={{
                    position: tablet ? 'absolute' : 'relative',
                    display: 'flex',
                    overflowX: 'auto',
                    gap: '.5rem',
                    mt: '-205px',
                    overflow: 'scroll',
                    width: '100%',
                    height: '60px',
                    paddingBottom: '2rem',
                    overflowY: 'hidden',
                    px: '1rem',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    ...!tablet && {
                        alignContent: 'flex-start',
                    },
                    ...tablet && {
                        bottom: '100px',
                        left: '0',
                        zIndex: 10,
                        width: '100%',
                        mt: '0',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbar-width': 'none',
                    }
                }}
            >
                {(
                    !hasActiveConversation()
                ) && (
                    <WhiteButton
                        onClick={() => auth ? handleOpenActionForm('openConversationForm') : handleOpenActionForm('openPhoneLoginForm')}
                        endIcon={<ArrowRight/>}
                        color={'#023766'}
                        m={'0'}
                        mw={'max-content'}
                    >
                        Parler à un agent
                    </WhiteButton>
                )}
                {/*<WhiteButton*/}
                {/*    endIcon={<ArrowRight/>}*/}
                {/*    color={'#023766'}*/}
                {/*    m={'0'}*/}
                {/*    mw={'max-content'}*/}
                {/*>*/}
                {/*    Recommandations*/}
                {/*</WhiteButton>*/}
            </Box>
            {auth && (
                <>
                    <AskConversationForm
                        open={openConversationForm}
                        onClose={closeOpenConversationForm}
                        onSuccess={handleSuccessfullSubmitSubject}
                    />
                    <ContinueConversationForm
                        open={openContinueConversationForm}
                        onClose={closeOpenContinueConversationForm}
                    />
                </>
            )}
            {phoneNumber && (
                <PasswordlessLoginForm
                    open={openPasswordlessForm}
                    onClose={closeOpenPasswordlessForm}
                    onSuccessfullLogin={handleOnLoginSuccess}
                    phoneNumber={phoneNumber}
                />
            )}
            {!auth && (
                <>
                    <PhoneLoginForm
                        open={openPhoneLoginForm}
                        onClose={closeOpenPhoneLoginForm}
                        onSuccessfulOtpSent={handleOnOTPSuccess}
                    />
                </>
            )}
        </>
    );
}

