import {Divider} from "@mui/material";
import {IHorizontalProps} from "./props";


export const HorizontalDivider = ({sx, color= '#FFF', variant = 'fullWidth'}: IHorizontalProps) => {
    return (
        <Divider
            color={color}
            variant={variant}
            sx={{
                borderWidth: '1px',
                opacity: 0.8,
                ...sx && sx
            }}
        />
    )
}
