import {useCallback} from "react";
import {
    addConversation,
    setConversationsList,
    setCurrentConversation,
    setUserStatus,
    updateConversationList
} from "../../../redux/conversation/chatSlice";
import {chatSelectors} from "../../../redux/conversation/selectors";
import {ChatState} from "../../../redux/conversation/types";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/useRedux";
import {IConversation} from "../entities";


export const usePrivateChat = () => {
    const {
        conversation: currentConversation,
        conversationsList
    } = useAppSelector(chatSelectors);

    const dispatch = useAppDispatch()
    
    const rememberCurrentConversation = useCallback((conversation: ChatState['conversation']) => {
        dispatch(setCurrentConversation(conversation))
    }, [dispatch])
    
    const setCurrentConversationUserStatus = useCallback((status: ChatState['userStatus']) => {
        dispatch(setUserStatus(status))
    }, [dispatch])
    
    const addConversationToList = useCallback((conversation: IConversation) => {
        dispatch(addConversation(conversation))
    }, [dispatch])
    
    const setCurrentUserConversationsList = useCallback((conversationsList: IConversation[]) => {
        dispatch(setConversationsList(conversationsList))
    }, [dispatch])
    
    const updateConversation = useCallback((conversationId: number, conversation: Partial<IConversation>) => {
        dispatch(updateConversationList({
            conversationId,
            conversation
        }))
    }, [dispatch])
    
    
    return {
        currentConversation,
        rememberCurrentConversation,
        setCurrentConversationUserStatus,
        addConversationToList,
        conversationsList,
        setCurrentUserConversationsList,
        updateConversation
    }
}
