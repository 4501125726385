import styled from "@emotion/styled";
import {Save} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button, FormControl} from "@mui/material";
import CircularLoader from "../Loader/CircularLoader";
import {SideModal} from "../SideModal";
import {ICrudFormProps} from "./props";


export const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    maxWidth: '500px',
    width: '100%',
});

export const FormControlWrapper = styled(FormControl)({
    margin: '0.5rem 0',
});

export const FormAction = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem',
    '& > button': {
        marginLeft: '0.5rem',
    },
});

const CrudForm = ({
    open,
    onClose,
    isSubmitting,
    title,
    onSubmit,
    onRenderCancelButton,
    onRenderSubmitButton,
    isEdition,
    saveButtonLabel,
    saveButtonIcon,
    addButtonLabel,
    addButtonIcon,
    children
}: ICrudFormProps) => {
    
    return (
        <SideModal
            open={open}
            onClose={onClose}
            title={title}
        >
            <Form onSubmit={onSubmit}>
                {children}
                <FormAction>
                    {undefined !== onRenderCancelButton ? onRenderCancelButton() : (
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                            disabled={isSubmitting}
                            onClick={onClose}>
                            <CloseIcon/>
                            Annuler
                        </Button>
                    )}
                    {undefined !== onRenderSubmitButton ? onRenderSubmitButton() : (
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}>
                            {isEdition ? (
                                <>
                                    {saveButtonIcon ? saveButtonIcon : <Save/>}
                                    {saveButtonLabel ? <span>{saveButtonLabel}</span> : <span>Enregistrer</span>}
                                </>
                            ) : (
                                 <>
                                     {isSubmitting ? (
                                         <>
                                             <CircularLoader w={22} h={22}/>
                                             <span>En cours...</span>
                                         </>
                                     ) : (
                                          <>
                                              {addButtonIcon ? addButtonIcon : <AddIcon/>}
                                              {addButtonLabel ? <span>{addButtonLabel}</span> : <span>Créer</span>}
                                          </>
                                      )}
                                 </>
                             )}
                        </Button>
                    )}
                </FormAction>
            </Form>
        </SideModal>
    );
}

export default CrudForm;

