import React, {useCallback, useEffect, useState} from "react";
import {useChat} from "../../../../hooks";
import {usePrivateChat} from "../../hooks";
import {useCreateMessageMutation} from "../../services/messageApi";
import {ChatInput} from "../ChatInput";
import {PrivateChatBody} from "../PrivateChatBody";
import {ArchivedConversation} from "../PrivateConversation";
import {usePrivateChatBody} from "../../hooks/usePrivateChatBody";


export const PrivateChatBox = () => {
    const [file, setFile] = useState<File | null>(null)
    const {currentConversation, updateConversation} = usePrivateChat()
    const {realtime} = useChat()
    const {addMessage} = usePrivateChatBody()
    const [
        createMessage, {
            isError: hasCreationError,
            isSuccess: isCreatedWithSuccess,
            error: creationError,
            data: createdMessage,
        }
    ] = useCreateMessageMutation()

    let ioIntervalEmitter: any = null

    useEffect(() => {

        let timer: any = null

        if (currentConversation && !currentConversation.is_closed) {

            realtime().on('online', (data: any) => {
                timer = setTimeout(() => {
                    realtime().emit('online')
                }, 2000)
            })
        }

        return () => {
            realtime().off('online')
            clearTimeout(timer)
        }
    }, [currentConversation])

    useEffect(() => {
        if (isCreatedWithSuccess) {
            setFile(null)
        }

        if (isCreatedWithSuccess && createdMessage) {

            updateConversation(createdMessage.conversation_id, {
                last_message: createdMessage.content,
                unread_count: currentConversation!.id === createdMessage.conversation_id ? undefined : 1,
            })
            addMessage({
                ...createdMessage,
                isMine: true
            })
        }
    }, [addMessage, createdMessage, currentConversation, isCreatedWithSuccess, updateConversation])


    const handleOnSendMessage = useCallback((message: string) => {
        realtime().emit('stoptyping', {
            room_id: 'support-' + currentConversation!.support_id,
            conversation_id: currentConversation!.id
        })
        createMessage({
            data: {
                content: message,
                ...file && {attachment: file}
            },
            reference: currentConversation!.conversation_reference,
            parentId: currentConversation!.id
        })
    }, [createMessage, currentConversation, file])

    const onRecordStarted = useCallback(() => {
        ioIntervalEmitter = setInterval(() => {
            realtime().emit('audio:recording', {
                room_id: 'support-' + currentConversation!.support_id,
                conversation_id: currentConversation!.id
            })
        }, 1000)
    }, [currentConversation, realtime])

    const onRecordStopped = useCallback(() => {
        if (ioIntervalEmitter) {
            realtime().emit('audio:stoprecording', {
                room_id: 'support-' + currentConversation!.support_id,
                conversation_id: currentConversation!.id
            })
            clearInterval(ioIntervalEmitter)
        }
    }, [currentConversation, ioIntervalEmitter])

    const onTyping = useCallback(() => {
        realtime().emit('typing', {
            room_id: 'support-' + currentConversation!.support_id,
            conversation_id: currentConversation!.id
        })
    }, [currentConversation, realtime])

    const onStopTyping = useCallback(() => {
        realtime().emit('stoptyping', {
            room_id: 'support-' + currentConversation!.support_id,
            conversation_id: currentConversation!.id
        })
    }, [currentConversation, realtime])


    return (
        <>
            <PrivateChatBody/>
            {(
                currentConversation && !currentConversation.is_closed
            ) ? (
                <ChatInput
                    onSend={handleOnSendMessage}
                    onChooseFile={(file) => setFile(file)}
                    onRecordedAudio={(audioFile) => setFile(audioFile)}
                    onClearFile={() => setFile(null)}
                    onStartRecording={onRecordStarted}
                    onStopRecording={onRecordStopped}
                    onStopTyping={onStopTyping}
                    onStartTyping={onTyping}
                />
            ) : (
                <ArchivedConversation/>
            )}
        </>
    )
}
