import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import {Box, IconButton, TextField} from "@mui/material";
import React, {useCallback, useRef, useState} from "react";
import {FileInfo} from "../../../../lib/components/FileInfo";
import {AudioRecorder} from "../../../../packages/audio-recorder";
import {getFileMetadata} from "../../../../utils";
import {IChatInputProps} from "./props";


export const ChatInput = ({
                              onSend,
                              onRecordedAudio,
                              onChooseFile,
                              onClearFile,
                              onStartRecording,
                              onStopRecording,
                              onStartTyping,
                              onStopTyping
                          }: IChatInputProps) => {
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const fileRef = useRef<HTMLInputElement>(null);
    const [fileMetadata, setFileMetadata] = useState<{ name: string, size: number, type: string } | null>(null);
    const [isRecording, setIsRecording] = useState<boolean>(false);

    const handleSend = () => {

        const message = messageRef.current!.value.trim()

        if (message.length > 1 || fileMetadata) {
            onSend(message || fileMetadata?.name);
            messageRef.current!.setSelectionRange(0, 0);
            messageRef.current!.value = '';
            messageRef.current!.focus();
            setFileMetadata(null)
            if (fileRef.current) {
                fileRef.current.value = '';
            }
        }
    }
    const handleChooseFile = () => {
        if (fileRef.current) {
            const file = fileRef.current!.files![0];
            onChooseFile?.(file);
            setFileMetadata(getFileMetadata(file));
        }
    }

    const handleRecordedAudio = useCallback((audio: File) => {
        onRecordedAudio?.(audio);
        setFileMetadata(getFileMetadata(audio));
        onStopRecording?.()
    }, [onRecordedAudio, onStopRecording])

    const handleNormalTyping = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();

            if (!e.shiftKey) {
                handleSend();
            }
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: 'calc(100% - 0)',
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    margin: '0 auto',
                    background: 'white',
                    padding: '1rem',
                }}
            >
                {fileMetadata && (
                    <FileInfo
                        name={fileMetadata.name}
                        size={fileMetadata.size}
                        type={fileMetadata.type}
                        onClear={() => {
                            setFileMetadata(null)
                            onClearFile?.()
                        }}
                    />
                )}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <TextField
                        sx={{
                            background: 'white',
                            '& .MuiFilledInput-root': {
                                background: 'white',
                                border: 'none',
                                '* &:hover': {
                                    background: 'white',
                                    '* &:before': {
                                        borderBottom: 'none',
                                    }
                                },
                                '* &:focus': {
                                    background: 'white',
                                },
                                '* &::before': {
                                    border: 'none',
                                },
                                '* &::after': {
                                    border: 'none',
                                }
                            }

                        }}
                        fullWidth
                        multiline
                        rows={2}
                        placeholder={'Ecrivez votre message ici'}
                        variant={'filled'}
                        inputRef={messageRef}
                        onKeyDown={(event) => {
                            if (undefined !== onStartTyping) {
                                if (event.key === 'Enter') {
                                    event.preventDefault();

                                    if (!event.shiftKey) {
                                        handleSend();
                                    }
                                } else {
                                    if (messageRef.current?.value.trim().length === 0) {
                                        onStopTyping?.()
                                    } else {
                                        onStartTyping?.()
                                    }
                                }
                            } else {
                                handleNormalTyping(event)
                            }
                        }}
                        disabled={isRecording}
                    />
                    {(
                        !isRecording
                    ) && (
                        <IconButton
                            onClick={handleSend}
                            sx={{
                                background: 'white',
                                '& .MuiSvgIcon-root': {
                                    color: '#023766',
                                }
                            }}
                        >
                            <SendIcon/>
                        </IconButton>
                    )}
                    {(
                        undefined !== onRecordedAudio
                    ) && (
                        <>
                            <AudioRecorder
                                onRecordStart={() => {
                                    setIsRecording(true)
                                    onStartRecording?.()
                                }}
                                onRecordStop={() => {
                                    setIsRecording(false)
                                    onStopRecording?.()
                                }}
                                onRecordedAudio={handleRecordedAudio}
                            />
                            {(
                                !isRecording && undefined !== onChooseFile
                            ) && (
                                <IconButton
                                    onClick={() => fileRef.current?.click()}
                                    sx={{
                                        backgroundColor: 'transparent!important',
                                        '& .MuiSvgIcon-root': {
                                            color: '#023766',
                                        }
                                    }}
                                >
                                    <AttachFileIcon/>
                                </IconButton>
                            )}
                        </>
                    )}
                </Box>
                {(
                    undefined !== onRecordedAudio
                ) && (
                    <>
                        {!isRecording && (
                            <input
                                style={{display: 'none'}}
                                accept={'image/*,video/*,audio/*,application/pdf'}
                                ref={fileRef}
                                type="file"
                                name="attachment"
                                id="attachment"
                                onChange={handleChooseFile}
                            />
                        )}
                    </>
                )}
            </Box>
        </>
    )
}
