import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {AuthRoutesType} from "./types";


const LogoutPage = lazy(() => import("../../../modules/auth/pages/logout-page"));

export const authRoutes: AuthRoutesType = {
    logout: {
        path: '/logout',
        component: <LogoutPage/>,
        title: 'Déconnexion',
        icon: <ExitToAppIcon/>
    }
}


export const authSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(authRoutes)
    .map(([_, {component, ...rest}]) => ({
        ...rest,
    }))
