import {useCallback} from "react";
import {useStorage} from "../packages/storage";
import {
    clearConversation,
    setConversationAgentName,
    setConversationId,
    setConversationRef,
    setConversationStatus,
    setConversationSupportId,
} from "../redux/conversation/conversationSlice";
import {conversationSelector} from "../redux/conversation/selectors";
import {ConversationStatus} from "../redux/conversation/types";
import {useAppDispatch, useAppSelector} from "../redux/hooks/useRedux";


export const useConversation = () => {
    const {remember} = useStorage()
    const {status, reference, agent_name, conversation_id, is_archive, supportId} = useAppSelector(conversationSelector)
    
    const dispatch = useAppDispatch()
    
    const rememberConversationRef = useCallback((ref: string) => {
        remember('conversation_ref', ref)
        dispatch(setConversationRef(ref))
    }, []);
    
    const updateConversationStatus = useCallback((status: ConversationStatus) => {
        remember('conversation_status', status)
        dispatch(setConversationStatus(status))
    }, []);
    
    const updateConversationId = useCallback((id: number) => {
        remember('conversation_id', id)
        dispatch(setConversationId(id))
    }, []);
    
    const updateConversationAgentName = useCallback((name: string) => {
        remember('conversation_agent_name', name)
        dispatch(setConversationAgentName(name))
    }, []);
    
    const setSupportId = useCallback((support_id: string) => {
        remember('support_id', support_id)
        dispatch(setConversationSupportId(support_id))
    }, [dispatch])
    
    const terminateConversation = useCallback(() => {
        dispatch(clearConversation())
    }, [dispatch])
    
    const isApproved = useCallback(() => {
        return status === ConversationStatus.APPROVED
    }, [status]);
    
    return {
        reference,
        supportId,
        status,
        agent_name,
        isArchived: is_archive,
        isApproved,
        rememberConversationRef,
        updateConversationStatus,
        conversation_id,
        updateConversationId,
        updateConversationAgentName,
        setSupportId,
        terminateConversation
    }
}
