import {createContext} from "react";
import {IMessage} from "../modules/chatbot/entities";

export interface PrivateChatBodyContextProps {
    addMessage: (message: IMessage) => void
    addMessages: (messages: IMessage[]) => void
    initMessages: (messages: IMessage[]) => void
    messages: IMessage[]
}

export const PrivateChatBodyContext = createContext<PrivateChatBodyContextProps | null>(null)
