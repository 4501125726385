import {HeadsetMic} from "@mui/icons-material";
import {BotChatBox} from "../components/BotChatBox";
import PrivateConversation from "../components/PrivateConversation/PrivateConversation";
import {BotChatRoutesType, PrivateChatRoutesType} from "./types";


export const privateChatRoutes: PrivateChatRoutesType = {
    messenger: {
        path: '/messenger/conversations/:id/threads/:threadId',
        component: <PrivateConversation/>,
        title: 'Messagerie',
        icon: <HeadsetMic/>,
        hasNestedRoutes: false
    },
}

export const chatBotRoutes : BotChatRoutesType = {
    bot : {
        path: '/',
        component: <BotChatBox/>,
        title: 'Bot',
        icon: <HeadsetMic/>,
        hasNestedRoutes: false
    }
}
