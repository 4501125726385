import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ILiveTypingProps} from "./props";
import {nl2br, urlify} from "../../../../utils";

const VOID_ELEMENTS = ['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr'];

export const LiveTyping = ({text, color, typingSpeed = 10}: ILiveTypingProps) => {
    const [displayedText, setDisplayedText] = useState('');

    const findClosingTagIndex = useCallback((text: string, currentIndex: number) => {
        let stack = [];
        let index = currentIndex;

        while (index < text.length) {
            const isOpeningTag = text[index] === '<' && text[index + 1] !== '/';
            const isClosingTag = text[index] === '<' && text[index + 1] === '/';

            if (isOpeningTag) {
                const tagNameEndIndex = text.indexOf('>', index);
                const tagName = text.slice(index + 1, tagNameEndIndex).split(' ')[0];

                if (!VOID_ELEMENTS.includes(tagName.toLowerCase())) {
                    stack.push(index);
                } else {
                    // Return the index of the closing '>'
                    return tagNameEndIndex + 1;
                }
            } else if (isClosingTag) {
                stack.pop();

                if (stack.length === 0) {
                    // Return the index of the closing '>'
                    return index + 3;
                }
            }

            index++;
        }

        return -1;
    }, []);

    const updateText = useCallback(() => {

        let textIndex = displayedText.length;

        if (textIndex < text.length) {

            const nextChar = text.slice(textIndex, textIndex + 1);

            if (nextChar === '<') {

                const closingTagIndex = findClosingTagIndex(text, textIndex);

                if (closingTagIndex !== -1) {
                    setDisplayedText(text.slice(0, closingTagIndex));
                } else {
                    setDisplayedText(text.slice(0, textIndex + 1));
                }
            } else {
                setDisplayedText(text.slice(0, textIndex + 1));
            }
        }
    }, [displayedText, findClosingTagIndex, text]);

    useEffect(() => {
        const typingTimer = setTimeout(updateText, typingSpeed);

        return () => {
            clearTimeout(typingTimer);
        };
    }, [updateText, typingSpeed]);

    return useMemo(() => (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2px',
        }}>
        <span
            dangerouslySetInnerHTML={{
                __html: nl2br(urlify(displayedText)),
            }}
            style={{
                color: color,
                display: 'block',
                lineHeight: '1.5rem',
            }}
        />
            {displayedText.length < text.length && (
                <>
                    <span
                        style={{
                            marginLeft: '2px',
                            borderLeft: '2px solid',
                            animation: '.6s blink step-end infinite'
                        }}
                    />
                    <style>{`
                        @keyframes blink {
                            from, to { border-color: transparent }
                            50% { border-color: ${color}; }
                        }
                    `}</style>
                </>
            )}
        </div>
    ), [color, displayedText, text]);
};

