import {Box} from "@mui/material";
import React from "react";
import {Message} from "./Message";
import {IIMediaMessage} from "./props";
import {authenticateResource, nl2br} from "../../../utils";


export const AudioMessage = ({
                                 content,
                                 avatar,
                                 position,
                                 color,
                                 bg,
                                 attachment,
                                 id,
                                 username,
                                 date
                             }: IIMediaMessage) => {
    const {src} = attachment
    return (
        <>
            <Message
                avatar={avatar}
                bg={bg}
                color={color}
                position={position}
            >
                <Box
                    sx={{
                        backgroundColor: 'transparent',
                        marginTop: '-2rem',
                        marginLeft: '-1rem',
                        marginRight: '-1rem',
                        marginBottom: '-1rem',
                        width: 'calc(100% + 2rem)',
                        '& audio': {
                            padding: '20px',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '.8rem',
                            width: '100%',
                            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                        }
                    }}
                >
                    <Box
                        component="audio"
                        controls
                        src={authenticateResource(src)}
                        sx={{width: '100%', maxWidth: 400, marginY: 2}}
                    >
                        Your browser does not support the audio element.
                    </Box>
                </Box>
                <span style={{
                    color: color,
                    display: 'block',
                    lineHeight: '1.5rem',
                }}>
                    {nl2br(content as string)}
                </span>
                <span
                    style={{
                        fontSize: '10px',
                        color: color,
                        textAlign: 'right',
                        marginTop: '.5rem',
                        display: 'block',
                    }}
                >
                    {date}
                </span>
            </Message>
        </>
    )
}
