import {PropsWithChildren, useCallback} from "react";
import {JSONStorage} from "../classes";
import {IStorageContext, StorageContext} from "../context/storageContext"


const storage = new JSONStorage(60 * 60 * 24 * 7)
export const StorageProvider = ({children}: PropsWithChildren) => {
    
    const remember = useCallback((key: string, value: any, watch: boolean = false) => {
        storage.remember(key, value, 60 * 60 * 24 * 7, watch)
    }, [])
    
    const getItem = useCallback((key: string) => storage.get(key), [])
    
    const forget = useCallback((key: string | string[]) => {
        storage.forget(key)
    }, [])
    
    const isRemember = useCallback((key: string) => {
        return storage.exists(key)
    }, [])
    
    const isExpiredRemember = useCallback((key: string) => {
        return storage.isExpired(key)
    }, [])
    
    const context = {
        remember,
        forget,
        isRemember,
        isExpiredRemember,
        getItem
    } as IStorageContext
    
    return (
        <StorageContext.Provider value={context}>
            {children}
        </StorageContext.Provider>
    )
}

