import {LocationCity, NavigateNextOutlined} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {TextLoader} from "../../../lib/components/Loader";
import {useGetBrigadesQuery} from "../services/brigadeApi";
import {CollapseSideBarItem} from "../../../lib/components/CollapseSideBarItem";
import {WhiteButton} from "../../../lib/components/Button";


export const BrigadeList = () => {
    const [page, setPage] = React.useState(1)
    const [hasMore, setHasMore] = React.useState(true)
    const {data, isLoading} = useGetBrigadesQuery({
        limit: 50,
        page: page,
    })

    useEffect(() => {
        if (data) {
            if (data.items!.length < 50) {
                setHasMore(false)
            } else if (data.metadata!.pages! > page) {
                setHasMore(true)
            }
        }
    }, [data, page])

    if (isLoading) {
        return (
            <TextLoader/>
        )
    }

    return (
        <>
            {(data && data.items!.length > 0) && (
                <>
                    <CollapseSideBarItem
                        title={(
                            <Typography
                                variant={'h5'}
                                sx={{
                                    fontSize: '1.2rem'
                                }}
                            >
                                Liste des brigades
                            </Typography>
                        )}
                        icon={<LocationCity/>}
                        key={'brigades'}
                        open={false}
                    >
                        <>
                            {data.items!.map((brigade, index) => {
                                return (
                                    <Box
                                        key={index}
                                        className={'message__item'}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '1rem',
                                            p: '1rem',
                                            height: 'calc(70px + 1rem)',
                                            background: 'transparent',
                                            '&, & *': {
                                                cursor: 'none',
                                                pointerEvents: 'none',
                                                transition: 'all 0.3s ease',
                                            },
                                            '&:hover': {
                                                background: '#F1F4F6',
                                            }
                                        }}
                                    >
                                        <LocationCity/>
                                        <Box sx={{
                                            overflow: 'hidden',
                                            position: 'relative'
                                        }}
                                        >
                                            <Typography
                                                variant={'h5'}
                                                sx={{
                                                    fontSize: '1rem',
                                                    fontWeight: '400'
                                                }}
                                            >
                                                {brigade.name}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                                variant={'body2'}
                                            >
                                                Numeros: {brigade.phone_number}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )
                            })}
                            {hasMore && (
                                <WhiteButton
                                    endIcon={<NavigateNextOutlined/>}
                                    onClick={() => setPage(page + 1)}
                                    sx={{
                                        '& div': {
                                            justifyContent: 'center!important',
                                            alignItems: 'center!important',
                                            gap: '.5rem',
                                        }
                                    }}
                                >
                                    <Typography
                                        variant={'body2'}
                                    >
                                        Suivant
                                    </Typography>
                                </WhiteButton>
                            )}
                        </>
                    </CollapseSideBarItem>
                </>
            )}
        </>
    )
}
