import CloseIcon from "@mui/icons-material/Close";
import {Box, Dialog} from "@mui/material";
import {StyledIconButton} from "../StyledIconButton";
import {IImagePreviewProps} from "./props";


export const ImagePreview = ({alt, src, show, onClose, ...css}: IImagePreviewProps) => {
    return (
        <>
            <Dialog
                open={show}
                onClose={onClose}
                maxWidth={'lg'}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        background: 'transparent',
                        boxShadow: 'none',
                        overflow: 'hidden',
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100vw',
                        background: 'rgba(0, 0, 0, 0.5)',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        zIndex: 100,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            background: 'rgb(248, 248, 251)',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <StyledIconButton
                            sx={{
                                position: 'absolute',
                                top: '1rem',
                                right: '1rem',
                                zIndex: 100,
                            }}
                            onClick={onClose}
                        >
                            <CloseIcon/>
                        </StyledIconButton>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                position: 'relative',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            <img
                                src={src}
                                alt={alt}
                                style={css}
                            />
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}
