import {Mic} from "@mui/icons-material";
import {Box} from "@mui/material";
import {IRecordingProps} from "./props";


export const Recording = ({text = 'enregistre un audio'}: IRecordingProps) => {
    return (
        <Box sx={{
            display: 'flex',
            gap: '.5rem',
            alignItems: 'center',
            animation: 'typing 1.5s infinite',
            '@keyframes typing': {
                '0%': {
                    transform: 'scale(0)'
                },
                '20%': {
                    transform: 'scale(1)'
                },
                '100%': {
                    transform: 'scale(1)'
                }
            }
        }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '.5rem',
                }}
            >
                <Mic sx={{
                    color: 'red',
                    fontSize: '1.5rem',
                }}/>
                {text}
            </Box>
            <Box sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#023766',
                animation: 'typing 1.5s infinite',
            }}></Box>
            <Box sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#023766',
                animation: 'typing 1.5s infinite',
                animationDelay: '0.3s'
            }}></Box>
            <Box sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#023766',
                animation: 'typing 1.5s infinite',
                animationDelay: '0.6s'
            }}></Box>
        </Box>
    )
}
