import React from 'react';
import {Box, Divider, Drawer, IconButton, Typography,} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import {ISideModalProps} from "./props";


export const SideModal = ({open, onClose, title, width, children}: ISideModalProps) => {
    return (
        <Drawer
            sx={{
                '& .MuiDrawer-paper': {
                    maxWidth: width || 400,
                    width: '100%',
                }
            }}
            anchor="bottom"
            open={open}
            variant={'temporary'}
            disableEscapeKeyDown={true}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                onClose();
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h6">{title}</Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Divider/>
            {children}
        </Drawer>
    );
}
