import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IToastState, ToastEnum} from "../../types";


const initialState: IToastState = {
    show: false,
    message: '',
    type: ToastEnum.NONE,
} as IToastState

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showToast: (state, action: PayloadAction<Omit<IToastState, 'show'>>) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
        hideToast: (state) => {
            state.show = false;
            state.message = '';
            state.type = ToastEnum.NONE
        }
    }
})

export const {showToast, hideToast} = toastSlice.actions;

export default toastSlice.reducer;
