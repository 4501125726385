import React from "react";
import {Message} from "./Message";
import {ITextMessage} from "./props";
import {LiveTyping} from "../../../modules/chatbot/components/LiveTyping";
import {nl2br} from "../../../utils";


export const TextMessage = ({
                                username,
                                content,
                                date,
                                id,
                                position,
                                avatar,
                                isLiveTyping = false,
                                bg = '#F7FBFF',
                                color = '#192A3E'
                            }: ITextMessage) => {
    return (
        <Message
            avatar={avatar}
            bg={bg}
            color={color}
            position={position}
        >
            {isLiveTyping ? (
                <LiveTyping color={color} text={content as string}/>
            ) : (
                <span
                    dangerouslySetInnerHTML={{
                        __html: nl2br(content as string)
                    }}
                    style={{
                        color: color,
                        display: 'block',
                        lineHeight: '1.5rem',
                    }}/>
            )}
            <span
                style={{
                    fontSize: '10px',
                    color: color,
                    textAlign: 'right',
                    marginTop: '.5rem',
                    display: 'block',
                }}
            >
                {date} {username && ' | Agent de la ' + username}
            </span>
        </Message>
    )
}
