import {Badge, Box, Typography} from "@mui/material";
import {IFlashableProps} from "./props";
import {LiveTyping} from "../../../modules/chatbot/components/LiveTyping";


export const Flashable = ({flashes, separator}: IFlashableProps) => {
    return (
        <>
            <Box sx={{
                width: '100%',
                position: 'relative',
            }}
            >
                <span
                    style={{
                        color: '#fff',
                        display: 'block',
                        marginBottom: '.5rem',
                        lineHeight: '1.5rem',
                        padding: '.5rem',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100px',
                        zIndex: 2,
                        background: '#023766',
                    }}
                >
                    NEWS :
                </span>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: '.8rem',
                    width: `calc(100% + ${flashes.length * 100}px)`, // Adjust the width based on the number of flash elements
                    marginLeft: '90px',
                    whiteSpace: 'nowrap',
                    // animate flash from right to left
                    animation: 'move-right-to-left 30s linear infinite',
                    transition: 'transform 10s ease-in-out',
                    '@keyframes move-right-to-left': {
                        '0%': {
                            transform: 'translateX(0)'
                        },
                        '100%': {
                            transform: 'translateX(-100%)'
                        },
                    }
                }}>
                    {flashes.length > 0 ?
                        (
                            <>
                                {flashes.map((flash, index) => {
                                    return <Typography
                                        variant={'body1'}
                                        component={'span'}
                                        key={index}
                                        sx={{
                                            color: '#fff',
                                            lineHeight: '1.5rem',
                                            background: 'transparent',
                                            padding: '.5rem',
                                            paddingRight: '1.5rem',
                                            fontWeight: '500',
                                            fontSize: '1rem',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            position: 'relative',
                                            gap: '3rem',
                                            marginRight: '100px', // Add a right margin to separate flash elements

                                        }}
                                    >
                                        <Badge
                                            badgeContent={flash.type.toUpperCase()}
                                            color={flash.type === 'danger' ? 'error' : flash.type === 'alert' ? 'warning' : 'info'}
                                            sx={{
                                                marginLeft: '1.5rem',
                                                '& .MuiBadge-badge': {
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    padding: '.2rem .5rem',
                                                    borderRadius: '5px',
                                                }
                                            }}
                                        />
                                        <LiveTyping text={flash.title} />
                                    </Typography>
                                })}
                            </>
                        ) : (
                            <Typography
                                variant={'body1'}
                                component={'span'}
                                sx={{
                                    color: '#fff',
                                    lineHeight: '1.5rem',
                                    background: 'transparent',
                                    padding: '.5rem',
                                    paddingRight: '1.5rem',
                                    fontWeight: '500',
                                    fontSize: '1rem',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    position: 'relative',
                                }}
                            >
                                Aucune actualité pour le moment
                            </Typography>
                        )
                    }
                </Box>
            </Box>
        </>
    );
}

