import {Box} from "@mui/material";
import React from "react";
import {Message} from "./Message";
import {IIMediaMessage} from "./props";
import {authenticateResource, nl2br} from "../../../utils";


export const VideoMessage = ({
                                        content,
                                        avatar,
                                        position,
                                        color,
                                        bg,
                                        attachment,
                                        id,
                                        username,
                                        date
                                    }: IIMediaMessage) => {
    const {src} = attachment
    return (
        <>
            <Message
                avatar={avatar}
                bg={bg}
                color={color}
                position={position}
            >
                <Box
                    sx={{
                        backgroundColor: 'transparent',
                        marginTop: '-1rem',
                        marginLeft: '-1rem',
                        marginRight: '-1rem',
                        marginBottom: '1rem',
                        '& video, & iframe': {
                            borderRadius: '1rem',
                            width: '100%',
                            objectFit: 'cover',
                            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                        }
                    }}
                >
                    {src.includes('youtube') ? (
                        <iframe
                            width="100%"
                            height="315"
                            src={src}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    ) : (
                        <video
                            controls
                            muted
                        >
                            <source
                                src={authenticateResource(src)}
                                type="video/mp4"
                            />
                        </video>
                    )}
                </Box>
                <span style={{
                    color: color,
                    display: 'block',
                    lineHeight: '1.5rem',
                }}>
                    {nl2br(content as string)}
                </span>
                <span
                    style={{
                        fontSize: '10px',
                        color: color,
                        textAlign: 'right',
                        marginTop: '.5rem',
                        display: 'block',
                    }}
                >
                    {date}
                </span>
            </Message>
        </>
    )
}
