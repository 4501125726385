import {IConversation} from "../../../modules/chatbot/entities";


export interface IConversationState {
    reference?: string | null;
    status?: ConversationStatus | null;
    agent_name?: string | null;
    is_archive: boolean;
    conversation_id: number | null,
    supportId?: string | null
}


export enum ConversationStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}

export interface ChatState {
    conversation?: IConversation | null;
    notifications?: number;
    userStatus: 'En ligne' | 'Hors ligne'
    conversationsList: IConversation[]
}
