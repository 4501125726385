import {Logout, Message, Support} from "@mui/icons-material";
import {Alert, Box, Fab, Typography} from "@mui/material";
import React, {PropsWithChildren, Suspense} from "react";
import {useNavigate} from "react-router-dom";
import {useMenuBreakPoints, useToast} from "../../hooks";
import {useMenu} from "../../hooks/useMenu";
import Toast from "../../lib/components/Toast/Toast";
import {useAuth} from "../../modules/auth/hooks/useAuth";
import {BrigadeList} from "../../modules/brigades/components";
import {ChatHeader} from "../../modules/chatbot/components";
import {AnoymousConversationList, ConversationList} from "../../modules/chatbot/components/ConversationList";
import {FlashProvider} from "../../providers/FlashProvider";
import {ToastEnum} from "../../types";
import {WhiteButton} from "../../lib/components/Button";
import {CollapseSideBarItem} from "../../lib/components/CollapseSideBarItem";
import SplashScreen from "../SplashScreen/SplashScreen";


interface IChatLayoutProps extends PropsWithChildren {
}


export const ChatLayout = ({children}: IChatLayoutProps) => {
    const {type, show, closeToast, message} = useToast()
    const {tablet, mobile} = useMenuBreakPoints()
    const {open, conversationListOpened, toggleConversationList} = useMenu()

    const {user: auth} = useAuth()
    const navigateTo = useNavigate()

    return (
        <>
            <Suspense fallback={<SplashScreen/>}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, rgba(185, 251, 255, 0.7) 0%, rgba(209, 201, 241, 0.7) 100%)`,
                    backgroundSize: 'cover',
                    backgroundBlendMode: 'multiply',
                    backdropFilter: 'blur(15px)',
                    WebkitBackdropFilter: 'blur(15px)',
                    position: 'relative',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                position: mobile ? 'absolute' : 'relative',
                                width: mobile ? '100%' : '300px',
                                background: '#fff',
                                height: '100vh',
                                display: (
                                    mobile && !conversationListOpened
                                ) ? 'none' : 'block',
                                ...mobile && {
                                    top: 100,
                                    zIndex: 11,
                                }
                            }}
                        >
                            {auth ? (
                                <>
                                    <ConversationList/>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 9999,
                                            background: '#023766',
                                            bottom: mobile ? '100px' : '0',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <WhiteButton
                                            onClick={() => navigateTo('/logout')}
                                            sx={{
                                                '& div': {
                                                    justifyContent: 'center!important',
                                                    gap: '1rem',
                                                },
                                            }}
                                        >
                                            <Typography>
                                                Déconnexion
                                            </Typography>
                                            <Logout/>
                                        </WhiteButton>
                                    </Box>
                                </>
                            ) : <AnoymousConversationList/>}
                        </Box>
                        <Box
                            sx={{
                                width: mobile ? '100%' : tablet ? 'calc(100% - 300px)' : 'calc(100% - 634px)',
                                height: '100vh',
                                position: 'relative',
                            }}
                        >
                            <FlashProvider>
                                <ChatHeader/>
                                {children}
                            </FlashProvider>
                            <Fab
                                onClick={toggleConversationList}
                                sx={{
                                    position: 'absolute',
                                    bottom: '7.5rem',
                                    right: '.3rem',
                                    display: mobile ? 'inline-flex' : 'none',
                                    zIndex: 12,
                                }}
                                color="primary" aria-label="Conversations">
                                <Message/>
                            </Fab>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexFlow: 'column',
                                gap: '1rem',
                                p: '1rem',
                                height: '100vh',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                zIndex: 9999,
                                maxWidth: {
                                    xs: '100%',
                                    md: 'calc(350px - 1rem)',
                                },
                                ...tablet && {
                                    top: '100px',
                                    left: '0',
                                    height: 'calc(100vh - 100px)',
                                    width: '100%',
                                    display: open ? 'block' : 'none',
                                }
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                sx={{
                                    fontSize: '1rem',
                                    mt: '1rem'
                                }}
                            >
                                Consignes d'utilisation du chat bot
                            </Typography>
                            <Box>
                                <ul>
                                    <li>
                                        <Typography variant="body1">
                                            Pour démarrer une nouvelle conversation, tapez simplement "Bonjour" ou
                                            "Salut"
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Pour poser une question, tapez votre question. Notre bot fera de son mieux
                                            pour
                                            vous donner une réponse précise et utile.
                                        </Typography>
                                        <Alert
                                            severity={'warning'}
                                            sx={{
                                                my: '1rem',
                                            }}
                                        >
                                            <Typography variant="body1">
                                                <b>Attention</b> : Le bot peut ne pas comprendre votre question si elle
                                                est trop longue ou si elle contient des fautes d'orthographe. Essayez de
                                                poser votre question en utilisant des mots simples et courts.
                                            </Typography>
                                            <br/>
                                            <Typography variant="body1">
                                                Il n'est pas en mesure de tenir une conversation générale. Il ne peut
                                                répondre qu'aux questions liées à la Brigade Numérique dont il a
                                                connaissance.
                                            </Typography>
                                        </Alert>
                                    </li>
                                    <li>
                                        <Typography variant="body1">
                                            Si vous ne comprenez pas la réponse du bot ou si vous souhaitez parler à un
                                            agent, cliquez sur "Parler à un agent" pour être redirigé vers un agent en
                                            direct.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>
                            <BrigadeList/>
                            <Box
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <CollapseSideBarItem
                                    title={'Notre mission'}
                                    icon={<Support/>}
                                    key={'mission'}
                                    open={false}
                                >
                                    <Typography
                                        sx={{
                                            my: '.8rem',
                                        }}
                                        variant="body1">
                                        La Brigade Numérique de Veille, d'Alerte et d'Assistance (BNVAA) a pour mission
                                        de répondre aux sollicitations
                                        des citoyens concernant les questions liées à la sécurité sur internet et aux
                                        nouvelles technologies. Voici quelques-unes de ses missions :
                                    </Typography>
                                    <ul>
                                        <li>
                                            <Typography variant="body1">
                                                Assistance aux victimes de cybercriminalité : la Brigade Numérique de
                                                Veille, d'Alerte et d'Assistance (BNVAA) peut aider les personnes qui
                                                ont été victimes d'attaques
                                                informatiques, de piratage de comptes, de chantage en ligne, etc.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">
                                                Sensibilisation à la sécurité numérique : la Brigade Numérique de
                                                Veille, d'Alerte et d'Assistance (BNVAA)
                                                peut sensibiliser le grand public et les entreprises aux risques liés à
                                                l'utilisation d'internet et des nouvelles technologies.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">
                                                Conseils en sécurité informatique : la Brigade Numérique de Veille,
                                                d'Alerte et d'Assistance (BNVAA)
                                                peut
                                                donner des conseils en matière de sécurité informatique aux particuliers
                                                et
                                                aux
                                                entreprises.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">
                                                Lutte contre la cybercriminalité : la Brigade Numérique de Veille,
                                                d'Alerte et d'Assistance (BNVAA)
                                                peut
                                                enquêter sur les affaires de cybercriminalité, comme le piratage
                                                informatique,
                                                la fraude en ligne, etc.
                                            </Typography>
                                        </li>
                                        <li>
                                            <Typography variant="body1">
                                                Surveillance des réseaux sociaux : la Brigade Numérique de Veille,
                                                d'Alerte et d'Assistance (BNVAA)
                                                peut
                                                surveiller les réseaux sociaux pour détecter les comportements suspects
                                                et
                                                prévenir les actes de délinquance en ligne.
                                            </Typography>
                                        </li>
                                    </ul>
                                </CollapseSideBarItem>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {(
                    type !== ToastEnum.NONE
                ) && (
                    <Toast
                        positionX="right"
                        positionY="top"
                        timer={3000}
                        severity={type}
                        sx={{width: '100%'}}
                        open={show}
                        onClose={closeToast}
                    >
                        {message}
                    </Toast>
                )}
            </Suspense>
        </>
    )
}
