import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {IJsonResourceResponse, PostItemArgs} from "../../../shared/types";
import {IRating} from "../entities";


export const ratingApi = createApi({
    reducerPath: 'ratingApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Ratings'],
    endpoints: (builder) => (
        {
            createRating: builder.mutation<IRating, PostItemArgs<IRating>>({
                query: ({data, parentId}) => {
                    return {
                        url: `/conversations/${parentId}/ratings`,
                        method: 'POST',
                        credentials: 'include',
                        data: data,
                    };
                },
                transformResponse: (result: IJsonResourceResponse<IRating, 'single'>) => result.item!,
            }),
        }
    ),
});

export const {
    useCreateRatingMutation,
} = ratingApi;
