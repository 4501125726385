import {createContext} from "react";


export interface IStorageContext {
    remember: (key: string, value: any, watch?: boolean) => void,
    forget: (key: string | string[]) => void,
    isRemember: (keyof: string) => boolean,
    isExpiredRemember: (keyof: string) => boolean,
    getItem: <T>(key: string) => T | null
}


export const StorageContext = createContext<IStorageContext | null>(null)
