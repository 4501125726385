import {Add, Search} from "@mui/icons-material";
import {Box, InputAdornment, TextField, Typography} from "@mui/material";
import React, {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useChat, useMenuBreakPoints} from "../../../../hooks";
import {useMenu} from "../../../../hooks/useMenu";
import {WhiteButton} from "../../../../lib/components/Button";
import {HorizontalDivider} from "../../../../lib/components/HorizontalDivider";
import LinearLoader from "../../../../lib/components/Loader/LinearLoader";
import {setCurrentConversation} from "../../../../redux/conversation/chatSlice";
import {IConversation} from "../../entities";
import {usePrivateChat} from "../../hooks";
import {useGetConversationsQuery} from "../../services/conversationApi";
import {ConversationLink} from "../ConversationLink";


export const ConversationList = () => {
    const [searchValue, setSearchValue] = React.useState('')
    const {isLoading, data, refetch} = useGetConversationsQuery({}, {
        refetchOnMountOrArgChange: true
    });
    const {realtime} = useChat()
    const {tablet, mobile} = useMenuBreakPoints()
    const {closeConversationList} = useMenu()
    const navigateTo = useNavigate()
    const {
        addConversationToList,
        setCurrentUserConversationsList,
        conversationsList,
        updateConversation,
        currentConversation
    } = usePrivateChat()

    const {addMessage} = useChat()

    const navigateToConversation = useCallback((conversation: IConversation) => {
        if (mobile) {
            closeConversationList()
        }
        navigateTo(`/messenger/conversations/${conversation.id}/threads/${conversation.conversation_reference}`)
    }, [mobile, closeConversationList, navigateTo])

    useEffect(() => {
        if (data) {
            setCurrentUserConversationsList(data.items!)
        }
    }, [data, setCurrentUserConversationsList])

    useEffect(() => {

        realtime().on('conversation:approved', ({data: {conversation, ...rest}}: { data: any }) => {
            addConversationToList(conversation)
            addMessage({
                id: Date.now().toString(),
                isMine: false,
                type: 'text',
                content: conversation.last_message,
                createdAt: new Date().toISOString(),
            })
            navigateToConversation(conversation)
        })

        realtime().on('conversation:rejected', ({data: {conversation_reference}}: { data: any }) => {
            realtime().emit('left', {
                room_id: conversation_reference
            })
            refetch()
        })
        realtime().on('conversation:archived', ({conversation_id}: { conversation_id: number }) => {
            if (currentConversation && currentConversation.id === conversation_id) {
                updateConversation(conversation_id, {
                    is_closed: true
                })
                setCurrentConversation(null)
                navigateTo('/')
            }
            refetch()
        })

        return () => {
            realtime().off('conversation:approved')
            realtime().off('conversation:rejected')
            realtime().off('conversation:archived')
        }
    }, [addConversationToList, refetch, currentConversation, setCurrentConversation, navigateTo])

    const readConversationMessages = useCallback((conversation: IConversation) => {
        // create a new array with the updated unread_count property
        updateConversation(conversation.id, {
            unread_count: undefined
        })
    }, [updateConversation])

    const handleLiveForActiveConversationsSearch = useCallback(() => {
        return conversationsList.filter((conversation: IConversation) => {
            return (
                conversation.subject.toLowerCase().includes(searchValue.toLowerCase()) ||
                conversation.last_message?.toLowerCase().includes(searchValue.toLowerCase())
            ) && !conversation.is_closed
        })
    }, [conversationsList, searchValue])

    const handleLiveForArchivedConversationsSearch = useCallback(() => {
        return conversationsList.filter((conversation: IConversation) => {
            return (
                conversation.subject.toLowerCase().includes(searchValue.toLowerCase()) ||
                conversation.last_message?.toLowerCase().includes(searchValue.toLowerCase())
            ) && conversation.is_closed
        })
    }, [conversationsList, searchValue])

    const renderStartNewConversation = useCallback(() => {
        return (
            <>
                <WhiteButton
                    onClick={() => {
                        if (mobile) {
                            closeConversationList()
                        }
                        navigateTo('/')
                    }}
                    mw={'calc(100% - 1rem)'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.8rem',
                        '& div': {
                            justifyContent: 'flex-start!important',
                        },
                    }}
                >
                    <Add/>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#023766',
                            '&:hover': {
                                color: '#fff'
                            }
                        }}
                        variant={'body1'}
                    >
                        Assistant virtuel
                    </Typography>
                </WhiteButton>
            </>
        )
    }, [mobile, closeConversationList, navigateTo])

    if (isLoading) {
        return <Box
            className={'side-bar__conversation'}
            sx={{
                position: 'absolute',
                left: 0,
                top: mobile ? 0 : tablet ? '130px' : 0,
                width: mobile ? '100%' : '300px',
                height: '100%',
                overflowY: 'scroll',
                background: '#023766',
                zIndex: 10,
                borderRadius: '20px',
            }}
        >
            <LinearLoader/>
        </Box>
    }

    if (conversationsList.length === 0) {
        return <Box
            className={'side-bar__conversation'}
            sx={{
                position: 'absolute',
                left: 0,
                top: mobile ? 0 : tablet ? '130px' : 0,
                width: mobile ? '100%' : '300px',
                height: '100%',
                overflowY: 'scroll',
                borderRadius: '20px',
                zIndex: 10,
                background: '#023766'
            }}
        >
            {renderStartNewConversation()}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexFlow: 'column',
                    gap: '1rem',
                    height: '100%',
                    p: '0 1rem',
                }}
            >
                <Typography
                    variant={'h5'}
                    sx={{
                        fontSize: '1.2rem',
                        fontWeight: '400',
                    }}
                >
                    Aucune conversation
                </Typography>
                <Typography
                    variant={'subtitle2'}
                    component={'p'}
                >
                    Cliquer sur le bouton ci-dessus pour créer une conversation
                </Typography>
            </Box>
        </Box>
    }

    return (
        <>
            <Box
                className={'side-bar__conversation'}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: mobile ? 0 : tablet ? '130px' : 0,
                    width: mobile ? '100%' : '300px',
                    height: '100%',
                    overflowY: 'scroll',
                    background: '#023766',
                    zIndex: 99,
                    pb: mobile ? '12rem' : '5rem',
                }}
            >
                {renderStartNewConversation()}
                {data && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexFlow: 'column',
                                gap: '1rem',
                                p: '.5rem',
                                position: 'sticky',
                                top: 0,
                                zIndex: 10,
                                background: '#023766',
                                '& *': {
                                    color: '#fff!important'
                                }
                            }}
                        >
                            <Typography
                                variant={'h5'}
                                sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: '400',
                                    mt: '1rem',
                                    pl: '1rem',
                                    mb: '1rem',
                                }}
                            >
                                Liste des conversations
                            </Typography>
                            <TextField
                                onChange={(e) => setSearchValue(e.target.value)}
                                fullWidth
                                type='search'
                                variant={'standard'}
                                placeholder={'Rechercher...'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            sx={{
                                                ml: '1rem'
                                            }}
                                            position="start">
                                            <Search/>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    borderRadius: '70px',
                                    background: '#023766!important',
                                    border: 'none',
                                    '& input': {
                                        borderRadius: '70px',
                                        background: '#023766!important',
                                        border: 'none',
                                        height: '50px',
                                        padding: '0 1rem',
                                        fontSize: '18px',
                                    },
                                    '& *': {
                                        '&, &::before, &::after': {
                                            borderBottom: 'none!important',
                                            '&::hover': {
                                                borderBottom: 'none!important'
                                            }
                                        },
                                    }
                                }}
                            />
                            <HorizontalDivider/>
                        </Box>
                        <Box>
                            <Typography
                                variant={'h5'}
                                sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: '400',
                                    mt: '.5rem',
                                    pl: '1rem',
                                    mb: '.5rem',
                                    py: '1rem',
                                    background: 'rgba(55, 65, 81, 0.48)',
                                    color: '#fff',
                                }}
                            >
                                Conversations actives
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                    p: '.5rem',
                                    '& .message__item': {
                                        borderBottom: 'solid 1px rgba(255, 255, 255, 0.48)',
                                        '&::last-child': {
                                            borderBottom: 'none'
                                        }
                                    }
                                }}
                            >
                                {handleLiveForActiveConversationsSearch().map((conversation: IConversation, index) => {
                                    return (
                                        <ConversationLink
                                            key={index}
                                            conversation={conversation}
                                            readConversationMessages={() => readConversationMessages(conversation)}
                                            setCurrentConversation={() => setCurrentConversation(conversation)}
                                            isTyping={false}
                                            currentTyper={null}
                                            isActive={currentConversation?.id === conversation.id}
                                        />
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box>
                            <Typography
                                variant={'h5'}
                                sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: '400',
                                    mt: '.5rem',
                                    pl: '1rem',
                                    mb: '.5rem',
                                    py: '1rem',
                                    background: 'rgba(55, 65, 81, 0.48)',
                                    color: '#fff',
                                    opacity: '.8'
                                }}
                            >
                                Conversations archivées
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                    p: '.5rem',
                                    '& .message__item': {
                                        borderBottom: 'solid 1px rgba(255, 255, 255, 0.48)',
                                        '&::last-child': {
                                            borderBottom: 'none'
                                        }
                                    }
                                }}
                            >
                                {handleLiveForArchivedConversationsSearch().map((conversation: IConversation, index) => {
                                    return (
                                        <ConversationLink
                                            key={index}
                                            conversation={conversation}
                                            readConversationMessages={() => readConversationMessages(conversation)}
                                            setCurrentConversation={() => setCurrentConversation(conversation)}
                                            isTyping={false}
                                            currentTyper={null}
                                            isActive={currentConversation?.id === conversation.id}
                                        />
                                    )
                                })}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}
