import {JSONStorage} from "../packages/storage";


export const formatDate = (date: Date) => {
    // dd/mm/yyyy hh:mm : (01/01/2020 12:00)
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    // prefix 0 if less than 10
    const dayStr = day < 10 ? `0${day}` : day;
    const monthStr = month < 10 ? `0${month}` : month;
    const hoursStr = hours < 10 ? `0${hours}` : hours;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;
    return `${dayStr}/${monthStr}/${year} ${hoursStr}:${minutesStr}:${secondsStr}`;
}


export enum SizeSuffixes {
    bytes = 0,
    KB = 1,
    MB = 2,
    GB = 3,
}


export function formatFileSize(bytes: number, suffixIndex = SizeSuffixes.bytes): string {

    const suffixes = ['bytes', 'KB', 'MB', 'GB'];

    if (suffixIndex > suffixes.length - 1) {
        throw new Error(
            `You provided an out of range suffixIndex: ${suffixIndex}.
             The maximum value is ${suffixes.length - 1}.
             You can use the SizeSuffixes enum to get the correct value.
             `
        )
    }

    if (bytes < 1000) return +bytes.toFixed(2) + ' ' + suffixes[suffixIndex];

    return formatFileSize(bytes / 1024, suffixIndex + 1);
}

export const getFileMetadata = (file: File) => {
    const {name, size, type} = file;
    return {
        name,
        size,
        type,
        src: getMediaUrl(file),
    };
}

export const getFileMediaType = (file: File) => {
    const FILE_TYPES_MAP = {
        image: 'image',
        video: 'video',
        audio: 'audio',
        application: 'file',
    }
    const {type} = file;
    const [mediaType] = type.split('/');
    return FILE_TYPES_MAP[mediaType as keyof typeof FILE_TYPES_MAP] as "image" | "video" | "audio" | "file";
}

export const getMediaUrl = (file: File) => {
    return URL.createObjectURL(file);
}

export const toFormData = (obj: any, form?: FormData, namespace?: string) => {
    // check if we have an initial form data else create a new one
    const formData = form || new FormData();
    let formKey: string;

    for (let property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an Array, we need to convert it to a string
            if (obj[property] instanceof Array) {
                // eslint-disable-next-line no-loop-func
                obj[property].forEach((element: any) => {
                    formData.append(String(formKey) + '[]', element);
                });
            }
                // if the property is an object, but not a File,
            // use recursivity.
            else if (typeof obj[property] === 'object' && !(
                obj[property] instanceof File
            )) {
                toFormData(obj[property], formData, property);
            } else {
                // if it's a string or a File object
                formData.append(formKey, obj[property]);
            }
        }
    }

    return formData;
}

export const storage = new JSONStorage(60 * 60 * 24 * 7); // 1 week

export const generateSecureRoomId = (base: string, secret: string) => {

    const start = base.slice(4, 6);
    const end = base.slice(10, 12);

    const cleanedNumber = base.replace(/^\+226\d{4}/, '');

    const maskedNumber = start + '226' + cleanedNumber.replace(/\d/g, 'X');

    return 'user-' + maskedNumber + end + secret;

}

export const authenticateResource = (resource: string) => {
    return resource;
}

export const nl2br = (str: string) => {
    return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, '<br/>');
}

export const urlify = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url: string)=> {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    })
}
