import {Box} from "@mui/material";
import {ITypingProps} from "./props";


export const Typing = ({text = 'écris'}: ITypingProps) => {
    return (
        <Box sx={{
            display: 'flex',
            gap: '.5rem',
            alignItems: 'center',
            animation: 'typing 1.5s infinite',
            '@keyframes typing': {
                '0%': {
                    transform: 'scale(0)'
                },
                '20%': {
                    transform: 'scale(1)'
                },
                '100%': {
                    transform: 'scale(1)'
                }
            }
        }}>
            {text}
            <Box sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#023766',
                animation: 'typing 1.5s infinite',
            }}></Box>
            <Box sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#023766',
                animation: 'typing 1.5s infinite',
                animationDelay: '0.3s'
            }}></Box>
            <Box sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                background: '#023766',
                animation: 'typing 1.5s infinite',
                animationDelay: '0.6s'
            }}></Box>
        </Box>
    )
}
