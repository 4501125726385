import {Add} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useMenuBreakPoints} from "../../../../hooks";
import {useMenu} from "../../../../hooks/useMenu";
import {WhiteButton} from "../../../../lib/components/Button";


export const AnoymousConversationList = () => {
    const {tablet, mobile} = useMenuBreakPoints()
    const {closeConversationList} = useMenu()
    const navigateTo = useNavigate()

    const renderStartNewConversation = useCallback(() => {
        return (
            <>
                <WhiteButton
                    onClick={() => {
                        if (mobile) {
                            closeConversationList()
                        }
                        navigateTo('/')
                    }}
                    mw={'calc(100% - 1rem)'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.8rem',
                        '& div': {
                            justifyContent: 'flex-start!important'
                        },
                    }}
                >
                    <Add/>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: '#023766',
                            '&:hover': {
                                color: '#fff'
                            }
                        }}
                        variant={'body1'}
                    >
                        Assistant virtuel
                    </Typography>
                </WhiteButton>
            </>
        )
    }, [mobile, closeConversationList, navigateTo])

    return (
        <>
            <Box
                className={'side-bar__conversation'}
                sx={{
                    position: 'absolute',
                    left: 0,
                    top: mobile ? 0 : tablet ? '130px' : 0,
                    width: mobile ? '100%' : '300px',
                    height: '100%',
                    overflowY: 'scroll',
                    zIndex: 10,
                    background: '#023766',
                    '& *': {
                        color: '#fff',
                    },
                }}
            >
                {renderStartNewConversation()}
                <Box
                    sx={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1rem',
                        p: '0 1rem',
                        height: '60%',
                    }}
                >
                    <Typography
                        variant={'subtitle2'}
                        component={'p'}
                    >
                        Cliquer sur le bouton ci-dessus pour créer une conversation
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
