import {Button} from "@mui/material";
import {IButtonProps} from "./props";


export const WhiteButton = ({
                                children,
                                onClick,
                                sx,
                                rd,
                                fs,
                                fw,
                                mw,
                                w,
                                p,
                                m,
                                bxs,
                                startIcon,
                                endIcon,
                                color = '#023766'
                            }: IButtonProps) => {
    return (
        <>
            <Button
                onClick={onClick}
                sx={{
                    ...sx && sx,
                    borderRadius: rd || '8px',
                    fontSize: fs || '14px',
                    fontWeight: fw || '400',
                    boxShadow: bxs || '0px 6px 18px rgba(0, 0, 0, 0.06)',
                    padding: p || '10px 11px',
                    margin: m || '10px',
                    maxWidth: mw || '245px',
                    width: w || '100%',
                    background: '#fff',
                    color: color || '#023766',
                    '&:hover': {
                        background: 'rgba(2, 55, 102, 1)',
                       '& *': {
                           color: '#fff',
                       }
                    }
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    {startIcon}
                    {children}
                    {endIcon}
                </div>
            </Button>
        </>
    )
}
