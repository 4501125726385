import MicIcon from "@mui/icons-material/Mic";
import {IconButton, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {IAudiorecorderProps} from "../props";


export const AudioRecorder = ({onRecordedAudio, onRecordStart, onRecordStop}: IAudiorecorderProps) => {
    const [recording, setRecording] = useState<boolean>(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [time, setTime] = useState(0);
    
    useEffect(() => {
        navigator.mediaDevices.getUserMedia({audio: true, video: false})
                 .then(stream => {
                     const recorder = new MediaRecorder(stream);
                     setMediaRecorder(recorder);
            
                     let chunks: BlobPart[] = [];
                     recorder.addEventListener('dataavailable', event => chunks.push(event.data));
                     recorder.addEventListener('stop', () => {
                         const blob = new Blob(chunks, {type: 'audio/mpeg'});
                         const file = new File([blob], 'recording.mp3', {type: blob.type});
                         onRecordedAudio(file);
                         setTime(0)
                         chunks = [];
                     });
                 })
                 .catch(error => console.error(error));
    }, [onRecordedAudio]);
    
    useEffect(() => {
        let timer: any;
        if (recording) {
            timer = setInterval(() => setTime(prevTime => prevTime + 1), 1000);
        } else {
            setTime(0);
        }
        return () => clearInterval(timer);
    }, [recording]);
    
    const startRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.start();
            setRecording(true);
            onRecordStart();
        }
    };
    
    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setRecording(false);
            onRecordStop();
        }
    };
    const formatTime = (timeInSeconds: number) => {
        const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
        const seconds = (
            timeInSeconds % 60
        ).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };
    
    return (
        <>
            <IconButton
                onClick={recording ? stopRecording : startRecording}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: .5,
                    backgroundColor: 'transparent!important',
                    '& .MuiSvgIcon-root': {
                        color: recording ? 'red' : '#023766',
                    }
                }}
            >
                <MicIcon/>
                {recording ? (
                    <Typography
                        variant={'subtitle2'}
                        component={'span'}
                        sx={{
                            color: 'red'
                        }}
                    >
                        {formatTime(time)}
                    </Typography>
                ) : null}
            </IconButton>
        </>
    );
};


