import {Box, FormHelperText, Rating, TextField} from "@mui/material";
import CrudForm from "../../../../lib/components/Form/CrudForm";
import {IRateFormProps} from "./props";
import React, {useEffect} from "react";
import {RateReview} from "@mui/icons-material";
import {useCreateRatingMutation} from "../../services/ratingApi";
import {useForm} from "react-hook-form";
import {RatingConversationFormType, RatingConversationSchema} from "../../schemas";
import {zodResolver} from "@hookform/resolvers/zod";
import useError from "../../../../hooks/useError";
import {useToast} from "../../../../hooks";
import {ToastEnum} from "../../../../types";
import {usePrivateChat} from "../../hooks";
import {useNavigate} from "react-router-dom";


export const RateAgentForm = ({open, onClose, conversation}: IRateFormProps) => {
    const {updateConversation} = usePrivateChat()

    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        control
    } = useForm<RatingConversationFormType>({
        resolver: zodResolver(RatingConversationSchema),
    });

    const {setErrors, getError, hasError} = useError(errors);

    const [rateConversation, {
        isLoading: isSubmitting,
        isSuccess: isSubmitted,
        isError: isSubmitError,
        error: submitError,
        data: rating
    }] = useCreateRatingMutation()
    const {toast, message} = useToast()
    const navigateTo = useNavigate()

    useEffect(() => {
        setErrors(errors)
    }, [errors, setErrors])

    useEffect(() => {
        if (isSubmitted) {
            toast("Votre note a été enregistrée avec succès", ToastEnum.SUCCESS)
        }
        if (isSubmitError) {
            toast(
                "Une erreur est survenue lors de l'enregistrement de votre note",
                ToastEnum.ERROR
            )
        }
    }, [isSubmitted, isSubmitError, toast, onClose])

    useEffect(() => {
        if (rating && isSubmitted) {
            updateConversation(conversation.id, {
                rating: rating.rate,
                feedback: rating.feedback
            })
            navigateTo(`/messenger/conversations/${conversation.id}/threads/${conversation.conversation_reference}`)
            onClose()
        }
    }, [rating, updateConversation, conversation, isSubmitted, onClose, navigateTo])
    const onFormSubmit = (data: RatingConversationFormType) => {
        rateConversation({
            parentId: conversation.id,
            data: {
                rate: data.rate,
                feedback: data.feedback
            }
        })
    }

    return (
        <>
            <CrudForm
                open={open}
                onClose={onClose}
                title={'Noter la conversation'}
                addButtonLabel={'Noter'}
                addButtonIcon={<RateReview/>}
                isSubmitting={isSubmitting}
                isEdition={false}
                onSubmit={handleSubmit(onFormSubmit)}
            >
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    <Rating
                        name={'rate'}
                        defaultValue={0}
                        size="large"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setValue('rate', newValue)
                            }
                        }}
                    />
                    {hasError('rate') &&
                        <FormHelperText
                            sx={{
                                pl: '1rem'
                            }}
                            error={true}>
                            {getError('rate')}
                        </FormHelperText>
                    }
                </Box>
                <TextField
                    type="text"
                    id="feedback"
                    label="Votre commentaire"
                    required
                    placeholder={'Votre commentaire'}
                    multiline
                    rows={2}
                    fullWidth
                    variant={'outlined'}
                    error={hasError('feedback')}
                    helperText={getError('feedback')}
                    {...register('feedback')}
                />
            </CrudForm>
        </>
    )
}
