import {Box, Rating, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AgentBot from "../../../../assets/images/agent";
import PersonImage from "../../../../assets/images/person";
import {useChat, useConversation, useFlash, useMenuBreakPoints} from "../../../../hooks";
import LinearLoader from "../../../../lib/components/Loader/LinearLoader";
import {AudioMessage, FileMessage, ImageMessage, Message, VideoMessage} from "../../../../lib/components/Message";
import {TextMessage} from "../../../../lib/components/Message/TextMessage";
import {Recording} from "../../../../lib/components/Recording";
import {Typing} from "../../../../lib/components/Typing";
import {formatDate} from "../../../../utils";
import {IConversation, IMessage} from "../../entities";
import {usePrivateChat} from "../../hooks";
import {useGetConversationMessagesQuery} from "../../services/messageApi";
import {usePrivateChatBody} from "../../hooks/usePrivateChatBody";
import {RateAgentForm} from "../RememberMeForm";
import {WhiteButton} from "../../../../lib/components/Button";
import {Star} from "@mui/icons-material";


export const PrivateChatBody = () => {
    const [openRateAgentForm, setOpenRateAgentForm] = useState(false)
    const {messages, initMessages, addMessage} = usePrivateChatBody()
    const {currentConversation, updateConversation} = usePrivateChat()
    const {id} = useParams()
    const {tablet} = useMenuBreakPoints()
    const {isLoading, data} = useGetConversationMessagesQuery({
        parentId: parseInt(id!)
    }, {
        refetchOnMountOrArgChange: true,
    })

    const {realtime, isTyping, isRecording, startRecording, startTyping} = useChat()

    const {hasFlash} = useFlash()
    const {agent_name} = useConversation()

    const viewRef = React.useRef<HTMLDivElement>(null)
    const marginTop = hasFlash ? '60px' : '0'

    useEffect(() => {
        if (data) {
            initMessages(data.items!)
        }
    }, [data, initMessages])

    useEffect(() => {
        if (viewRef.current) {
            // scroll to bottom
            viewRef.current.scrollTop = viewRef.current.scrollHeight
        }
    }, [messages, isTyping, isRecording])

    useEffect(() => {
        realtime().on('isTyping', () => {
            startTyping(true)
        })

        realtime().on('hasStopTyping', () => {
            startTyping(false)
        })

        realtime().on('isRecording', () => {
            startRecording(true)
        })

        realtime().on('hasStopRecording', () => {
            startRecording(false)
        })

        realtime().on('message', ({data: message}: { data: IMessage }) => {
            startTyping(false)
            startRecording(false)
            if (currentConversation!.id === message.conversation_id) {
                addMessage(message)

                realtime().emit('message:read', {
                    message_id: message.id,
                })
            }

            updateConversation(message.conversation_id, {
                last_message: message.content,
                unread_count: currentConversation!.id === message.conversation_id ? undefined : 1,
            })

        })

        return () => {
            realtime().off('message')
            realtime().off('isTyping')
            realtime().off('hasStopTyping')
            realtime().off('isRecording')
            realtime().off('hasStopRecording')
        }

    }, [currentConversation, messages, updateConversation])

    const handleOnCloseRateAgentForm = useCallback(() => {
        setOpenRateAgentForm(false)
    }, [])

    const renderRateAgentButton = useCallback(() => {
        return (
            <WhiteButton
                color={'#023766'}
                endIcon={<Star/>}
                onClick={() => setOpenRateAgentForm(true)}
            >
                Notez la conversation
            </WhiteButton>
        )
    }, [])

    const renderConversationRates = useCallback((conversation: IConversation) => {
        return (
            <Box
                sx={{
                    backgroundColor: '#fff',
                    width: '100%',
                    p: '1rem',
                    opacity: .9,
                    borderRadius: '12px',
                    m: '1rem auto'
                }}
            >
                <Typography
                    sx={{
                        mb: '.5rem'
                    }}
                    variant={'body2'}
                    color={'#192A3E'}>
                    Vous avez noté cette conversation comme étant :
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                    }}
                >
                    <Typography
                        variant={'body2'}
                        color={'#192A3E'}
                    >
                        Note : {conversation.rating} / 5
                    </Typography>
                    <Rating
                        name={'rate'}
                        value={conversation.rating}
                        size="large"
                        disabled={true}
                    />
                </Box>
                <Box
                    sx={{
                        mt: '.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem'
                    }}
                >
                    <Typography
                        variant={'body2'}
                        color={'#192A3E'}
                    >
                        Commentaire : {conversation.feedback}
                    </Typography>
                </Box>
            </Box>
        )
    }, [])

    if (isLoading) {
        return <LinearLoader/>
    }

    return (
        <Box sx={{
            height: {
                xs: 'calc(100vh)',
                md: 'calc(100% - 100px)',
            },
            padding: '0 1rem 1rem 1rem',
            position: 'relative',
        }}>
            <Box
                ref={viewRef}
                sx={{
                    height: {
                        xs: !currentConversation!.is_closed ? 'calc(100% - 185px)' : '100%',
                        md: !currentConversation!.is_closed ? 'calc(100% - 100px)' : '100%',
                    },
                    width: '100%',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    marginTop: {
                        xs: '140px',
                        md: marginTop,
                    },
                    paddingBottom: {
                        xs: marginTop !== '0' ? 'calc(38px + 4rem)' : marginTop,
                        md: marginTop !== '0' ? 'calc(38px + 5rem)' : marginTop,
                    }
                }}
            >
                {messages.map((message, index) => {
                    const position = message.isMine ? 'right' : 'left'
                    const color = message.isMine ? '#fff' : '#192A3E'
                    const bg = message.isMine ? '#023766' : '#fff'
                    const avatar = message.isMine ? PersonImage : AgentBot
                    const createdAt = formatDate(new Date(message.created_at))
                    const messageId = String(message.id)
                    switch (message.type) {
                        case 'text':
                            return <TextMessage
                                id={messageId}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={createdAt}
                                {...!message.isMine && {username: 'BNVAA'}}
                            />
                        case 'image':
                            return <ImageMessage
                                id={messageId}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={createdAt}
                                attachment={{
                                    name: message.file_name!,
                                    size: message.file_size!,
                                    src: message.file_path!
                                }}
                                {...!message.isMine && {username: 'BNVAA'}}
                            />
                        case 'video':
                            return <VideoMessage
                                id={messageId}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={createdAt}
                                attachment={{
                                    name: message.file_name!,
                                    size: message.file_size!,
                                    src: message.file_path!
                                }}
                                {...!message.isMine && {username: 'BNVAA'}}
                            />
                        case 'audio':
                            return <AudioMessage
                                id={messageId}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={createdAt}
                                attachment={{
                                    name: message.file_name!,
                                    size: message.file_size!,
                                    src: message.file_path!
                                }}
                                {...!message.isMine && {username: 'BNVAA'}}
                            />
                        case 'file':
                            return <FileMessage
                                id={messageId}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={createdAt}
                                attachment={{
                                    name: message.file_name!,
                                    size: message.file_size!,
                                    src: message.file_path!
                                }}
                                {...!message.isMine && {username: 'BNVAA'}}
                            />

                        default:
                            return <TextMessage
                                id={messageId}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                {...!message.isMine && {username: 'BNVAA'}}
                                date={createdAt}
                            />
                    }
                })}
                {(
                    isTyping || isRecording
                ) && (
                    <Message
                        position={'left'}
                        avatar={AgentBot}
                    >
                        {isTyping && <Typing/>}
                        {isRecording && <Recording/>}
                    </Message>
                )}
                {currentConversation && currentConversation.is_closed && (
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            overflowX: 'auto',
                            gap: '.5rem',
                            overflow: 'scroll',
                            width: '100%',
                            height: currentConversation.rating ? 'auto' : '60px',
                            paddingBottom: '2rem',
                            overflowY: 'hidden',
                            px: '1rem',
                            flexWrap: 'wrap',
                            flexDirection: 'column',
                            bottom: '7.3rem',
                            left: '0',
                            zIndex: 99,
                            ...!tablet && {
                                alignContent: 'flex-start',
                            },
                            ...tablet && {
                                bottom: '13rem',
                                left: '0',
                                zIndex: 10,
                                width: '100%',
                                mt: '0',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                '-ms-overflow-style': 'none',
                                'scrollbar-width': 'none',
                            }
                        }}
                    >
                        {currentConversation.rating ? renderConversationRates(currentConversation) : renderRateAgentButton()}
                    </Box>
                )}
            </Box>
            {currentConversation && !currentConversation.rating && currentConversation.is_closed && (
                <>
                    <RateAgentForm
                        open={openRateAgentForm}
                        conversation={currentConversation}
                        onClose={handleOnCloseRateAgentForm}
                    />
                </>
            )}
        </Box>
    )
}
