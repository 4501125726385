import {TypeOf, z} from 'zod';


export const PasswordlessLoginFormSchema = z.object({
    phone_number: z.string().regex(/^(00226|\+226|0)?[0-9]{8}$/, {
        message: 'Le numéro de téléphone doit être au format +226 70 00 00 00',
    }),
    otp_code: z.string({
            required_error: 'Le code otp est obligatoire',
        }
    ).min(6, {
        message: 'Le code otp doit contenir au moins 6 caractères',
    }).max(6, {
        message: 'Le code otp doit contenir au maximum 6 caractères',
    }),
});


export type PasswordlessLoginFormType = TypeOf<typeof PasswordlessLoginFormSchema>;

export const PhoneLoginFormSchema = z.object({
    phone_number: z.string().regex(/^(00226|\+226|0)?[0-9]{8}$/, {
        message: 'Le numéro de téléphone doit être au format +226 70 00 00 00',
    }),
});


export type PhoneLoginFormType = TypeOf<typeof PhoneLoginFormSchema>;
