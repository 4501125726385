import MuiAlert, {AlertProps} from '@mui/material/Alert'
import React, {forwardRef} from 'react'
import {Snackbar} from "@mui/material";
import {ToastProps} from "./props";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Toast = ({children, positionX, positionY, open, onClose, timer, ...others}: ToastProps) => {
    return (
        <Snackbar
            sx={{
                top: '66px!important',
                right: '32px!important',
                '& *' : {
                    color: 'neutral.0'
                }
            }}
            anchorOrigin={{vertical: positionY, horizontal: positionX}}
            open={open}
            autoHideDuration={timer}
            onClose={(_event?: React.SyntheticEvent | Event, reason?: string) => {
                if (reason === 'clickaway') {
                    return
                }
                onClose()
            }}>
            <Alert {...others}>
                {children}
            </Alert>
        </Snackbar>
    )
}


export default Toast
