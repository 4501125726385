import {createContext} from "react";


export interface MenuContextProps {
    open: boolean
    toggleMenu: () => void
    openMenu: () => void
    closeMenu: () => void
    toggleConversationList: () => void
    closeConversationList: () => void
    conversationListOpened: boolean
}


export const MenuContext = createContext<MenuContextProps | undefined>(undefined)
