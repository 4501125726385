import {Box} from "@mui/material";
import React, {useEffect} from "react";
import Bot from "../../../../assets/images/bot";
import PersonImage from "../../../../assets/images/person";
import {useChat, useFlash} from "../../../../hooks";
import {AudioMessage, FileMessage, ImageMessage, Message, VideoMessage} from "../../../../lib/components/Message";
import {TextMessage} from "../../../../lib/components/Message/TextMessage";
import {Typing} from "../../../../lib/components/Typing";
import {formatDate} from "../../../../utils";


export const ChatBody = () => {
    const {hasFlash} = useFlash()
    const {messages, isTyping} = useChat()

    const viewRef = React.useRef<HTMLDivElement>(null)
    const marginTop = hasFlash ? '38px' : '0'

    useEffect(() => {
        if (viewRef.current) {
            // scroll to bottom
            viewRef.current.scrollTop = viewRef.current.scrollHeight
        }
    }, [messages, isTyping])

    return (
        <Box sx={{
            height: {
                xs: 'calc(100vh)',
                md: 'calc(100% - 100px)',
            },
            padding: '0 1rem 1rem 1rem',
            position: 'relative',
        }}>
            <Box
                ref={viewRef}
                sx={{
                    height: {
                        xs: 'calc(100% - 185px)',
                        md: 'calc(100% - 210px)',
                    },
                    width: '100%',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    marginTop: {
                        xs: '140px',
                        md: marginTop,
                    },
                    paddingBottom: {
                        xs: marginTop !== '0' ? 'calc(38px + 4rem)' : marginTop,
                        md: marginTop !== '0' ? 'calc(38px + 5rem)' : marginTop,
                    }
                }}
            >
                <TextMessage
                    id={String(Date.now() + Math.random())}
                    avatar={Bot}
                    position={'left'}
                    date={formatDate(new Date())}
                    content="Bienvenue sur l'assistant de la Brigade Numérique de Veille, d'Alerte et d'Assistance (BNVAA) nationale."
                    isLiveTyping={true}
                />
                {messages.map((message, index) => {
                    const position = message.isMine ? 'right' : 'left'
                    const color = message.isMine ? '#fff' : '#192A3E'
                    const bg = message.isMine ? '#023766' : '#fff'
                    const avatar = message.isMine ? PersonImage : Bot
                    switch (message.type) {
                        case 'image':
                            return <ImageMessage
                                id={message.id}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={message.createdAt}
                                attachment={message.attachment!}
                            />
                        case 'video':
                            return <VideoMessage
                                id={message.id}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={message.createdAt}
                                attachment={message.attachment!}
                            />
                        case 'audio':
                            return <AudioMessage
                                id={String(message.id)}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={message.createdAt}
                                attachment={message.attachment!}
                            />
                        case 'file':
                            return <FileMessage
                                id={message.id}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={message.createdAt}
                                attachment={message.attachment!}
                            />
                        case 'text':
                        default:
                            return <TextMessage
                                id={message.id}
                                avatar={avatar}
                                bg={bg}
                                color={color}
                                position={position}
                                key={index}
                                content={message.content}
                                date={message.createdAt}
                                isLiveTyping={index === messages.length - 1 && !message.isMine}
                            />
                    }
                })}
                {(
                    isTyping
                ) && (
                    <Message
                        position={'left'}
                        avatar={Bot}
                    >
                        {isTyping && <Typing/>}
                    </Message>
                )}
            </Box>
        </Box>
    )
}
