import {ReactElement, ReactNode} from "react";

export enum ToastEnum {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
    NONE = 'none'
}



export type IRouteType = {
    path: string,
    icon: ReactElement,
    component: ReactNode,
    title: string
    subRoutes?: ISubRouteType[]
    permission?: string
    hasNestedRoutes?: boolean
}

export type ISubRouteType = {
    path: string,
    component: ReactNode,
    permission?: string
}

export interface IToastState {
    message: string | null
    show: boolean
    type: ToastEnum
}
