import {NavLink} from 'react-router-dom';
import {Avatar, Box, Typography,} from '@mui/material';
import {useMenuBreakPoints} from "../../../../hooks";
import {useMenu} from "../../../../hooks/useMenu";
import {StatusLabel} from "../../../../lib/components/StatusLabel";
import {IConversationLinkProps} from "./props";


export const ConversationLink = ({
    conversation,
    readConversationMessages,
    setCurrentConversation,
    isTyping,
    currentTyper,
    isActive
}: IConversationLinkProps) => {
    
    const {mobile} = useMenuBreakPoints()
    const {closeConversationList} = useMenu()
    const handleOnClick = () => {
        // mark conversation as read
        readConversationMessages(conversation);
        // set conversation as current conversation
        setCurrentConversation(conversation);
        
        if (mobile) {
            closeConversationList()
        }
    };
    
    return (
        <NavLink
            style={{
                textDecoration: 'none',
                color: 'inherit'
            }}
            key={conversation.id}
            to={`/messenger/conversations/${conversation.id}/threads/${conversation.conversation_reference}`}
            onClick={handleOnClick}
        >
            <Box
                className={'message__item'}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                    p: '1rem',
                    height: 'calc(70px + 1rem)',
                    background: 'transparent',
                    opacity: .8,
                    '&:hover': {
                        opacity: 1,
                        cursor: 'pointer'
                    },
                    '& *': {
                        color: '#fff!important'
                    },
                    ...(
                        isActive && {
                            opacity: 1,
                        }
                    )
                }}
            >
                <Avatar/>
                <Box sx={{
                    overflow: 'hidden',
                    position: 'relative',
                    width: '100%'
                }}>
                    {conversation.unread_count && conversation.unread_count > 0 && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '2px',
                                right: '1rem'
                            }}
                        >
                            <StatusLabel
                                label={conversation.unread_count}
                                color={'error'}
                                sx={{
                                    width: '22px',
                                    height: '22px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    'span': {
                                        pl: '2px',
                                        pr: '2px'
                                    }
                                }}
                            />
                        </Box>
                    )}
                    <Typography
                        variant={'h5'}
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '400'
                        }}
                    >
                        {conversation.subject}
                    </Typography>
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        variant={'body2'}
                    >
                        {isTyping && currentTyper === conversation.id
                         ? 'Entrain d\'ecrire...'
                         : conversation.last_message || conversation.subject}
                    </Typography>
                </Box>
            </Box>
        </NavLink>
    );
};
