import {Box} from "@mui/material";
import React, {useState} from "react";
import {ImagePreview} from "../ImagePreview";
import {Message} from "./Message";
import {IIMediaMessage} from "./props";
import {authenticateResource, nl2br} from "../../../utils";


export const ImageMessage = ({
    content,
    color,
    attachment,
    bg,
    avatar,
    position,
    id,
    username,
    date
}: IIMediaMessage) => {
    const [show, setShow] = useState(false)
    
    const {src} = attachment
    
    return (
        <>
            <Message
                avatar={avatar}
                bg={bg}
                color={color}
                position={position}
            >
                <Box
                    sx={{
                        backgroundColor: 'transparent',
                        marginTop: '-1rem',
                        marginLeft: '-1rem',
                        marginRight: '-1rem',
                        marginBottom: '1rem',
                        '& img': {
                            borderRadius: '1rem',
                            width: '100%',
                            objectFit: 'cover',
                            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                                cursor: 'pointer',
                            }
                        }
                    }}
                >
                    <img
                        onClick={() => setShow(true)}
                        src={authenticateResource(src)}
                        alt={attachment.name}
                    />
                </Box>
                <span style={{
                    color: color,
                    display: 'block',
                    lineHeight: '1.5rem',
                }}>
                    {nl2br(content as string)}
                </span>
                <span
                    style={{
                        fontSize: '10px',
                        color: color,
                        textAlign: 'right',
                        marginTop: '.5rem',
                        display: 'block',
                    }}
                >
                    {date}
                </span>
            </Message>
            <ImagePreview
                alt={attachment.name}
                src={authenticateResource(src)}
                show={show}
                onClose={() => setShow(false)}
                width={'100%'}
                height={'100%'}
                objectFit={'contain'}
            />
        </>
    )
}
