import {FilePresent} from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {Box, Link} from "@mui/material";
import React from "react";
import {Message} from "./Message";
import {IIMediaMessage} from "./props";
import {formatFileSize} from "./utils";
import {authenticateResource, nl2br} from "../../../utils";


export const FileMessage = ({
    attachment,
    avatar,
    date,
    username,
    content,
    position,
    color,
    bg,
    id
}: IIMediaMessage) => {
    const {src, size, name} = attachment
    return (
        <>
            <Message
                avatar={avatar}
                bg={bg}
                color={color}
                position={position}
            >
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        marginTop: '-1rem',
                        marginLeft: '-1rem',
                        marginRight: '-1rem',
                        marginBottom: '1rem',
                        borderRadius: '4px',
                        width: 'calc(100% + 2rem)',
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        padding: '0.9em 1.5em',
                        cursor: 'pointer',
                        background: `linear-gradient(90deg, #F5F5F5 0%, #F5F5F5 100%)`,
                        '&:hover': {
                            background: `linear-gradient(90deg, #F5F5F5 0%, #F5F5F5 100%)`,
                            opacity: 0.8,
                        },
                        '& *': {
                            color: '#023766',
                        }
                    }}>
                        <FilePresent/>
                        <Box sx={{
                            display: 'flex',
                            // flexFlow: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '1.5em',
                            minWidth: 0,
                            '& p': {
                                margin: 0,
                                fontSize: '12px',
                            },
                            gap: '.5rem',
                            '&::hover': {
                                cursor: 'pointer',
                            }
                        }}>
                            <Link
                                href={authenticateResource(src)}
                                download={name}
                                target="_blank"
                                rel="noreferrer"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#023766',
                                    '&:hover': {
                                        textDecoration: 'none',
                                    }
                                }}
                            >
                                <p style={{
                                    fontWeight: 700,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 1,
                                    WebkitBoxOrient: 'vertical',
                                }}>
                                    {name}
                                </p>
                                <p style={{
                                    fontWeight: 400,
                                }}>
                                    {size && formatFileSize(size) + ' - '} Télécharger
                                </p>
                            </Link>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <FileDownloadIcon/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <span style={{
                    color: color,
                    display: 'block',
                    lineHeight: '1.5rem',
                }}>
                    {nl2br(content as string)}
                </span>
                <span
                    style={{
                        fontSize: '10px',
                        color: color,
                        textAlign: 'right',
                        marginTop: '.5rem',
                        display: 'block',
                    }}
                >
                    {date}
                </span>
            </Message>
        </>
    )
}
