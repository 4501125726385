import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {storage} from "../../../../utils";
import {IAuthState, IUser} from "../../entities";


const user = storage.get<IUser | null>('user')

const initialState: IAuthState = {
    user: user,
    isAuthenticated: !!user,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticate: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload
            state.isAuthenticated = true
            storage.remember('user', action.payload, 60 * 24, true)
        },
        logout: (state) => {
            state.user = null
            state.isAuthenticated = false
            storage.forget(['user', 'token'])
        }
    }
})

export default authSlice.reducer
export const {authenticate, logout} = authSlice.actions
