import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {GetItemQueryArgs, GetItemsQueryArgs, IJsonResourceResponse,} from "../../../shared/types";
import {IRubric} from "../entities";


export const rubricApi = createApi({
    reducerPath: 'rubricApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Rubrics'],
    endpoints: (builder) => (
        {
            getRubrics: builder.query<IJsonResourceResponse<IRubric, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/rubrics?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                    ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Rubrics' as const,
                                    id,
                                }
                            )),
                            {type: 'Rubrics', id: 'LIST'},
                        ]
                    : [{type: 'Rubrics', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IRubric, 'multiple'>) => results,
            }),
            getRubric: builder.query<IRubric, GetItemQueryArgs>({
                query: (id) => {
                    return {
                        url: `/rubrics/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Rubrics', id}],
                transformResponse: (result: IJsonResourceResponse<IRubric, 'single'>) => result.item!,
            }),
        }
    ),
});

export const {
    useGetRubricsQuery,
    useGetRubricQuery,
} = rubricApi;
