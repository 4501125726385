import {useContext} from 'react'
import {StorageContext} from "../context/storageContext";


export const useStorage = () => {
    
    const context = useContext(StorageContext)
    
    if (!context) {
        throw new Error('useCache must be used within a CacheProvider')
    }
    
    return context
}
