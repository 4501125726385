import {ExpandMore, KeyboardArrowRight} from "@mui/icons-material";
import {Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import React, {useState} from "react";


import {CollapseSideBarItemProps} from "./props";


export const CollapseSideBarItem = ({
    children,
    icon,
    title,
    open: mustOpen = true,
    ...props
}: CollapseSideBarItemProps) => {
    const [open, setOpen] = useState(Boolean(mustOpen));
    
    /**
     * Toggle the collapse state of the sidebar nav item
     */
    const toggleCollapse = () => {
        setOpen(!open);
    }
    
    return (
        <>
            <ListItemButton
                className={'collapse-button'}
                sx={{
                    mx: 2,
                    borderRadius: 1,
                    flexGrow: 'unset',
                    '&:hover': {
                        backgroundColor: '#023766',
                        fontWeight: 'fontWeightBold',
                        '& *': {
                            color: '#fff!important'
                        },
                    },
                    backgroundColor: open ? '#023766' : 'transparent',
                    '& *': {
                        color: open ? '#fff' : 'neutral.900',
                    }
                }}
                onClick={toggleCollapse}>
                
                <Tooltip title={title} placement="right">
                    <>
                        <ListItemIcon
                            className={'collapse-button-icon'}
                            sx={{
                                minWidth: 'auto',
                                color: open ? 'neutral.100' : 'neutral.900',
                                '& svg': {
                                    fontSize: '1.5rem',
                                    fontWeight: 400
                                }
                            }}>
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            className={'collapse-button-text'}
                            sx={{
                                color: open ? '#fff' : 'neutral.100',
                                ml: '.5rem',
                                '& .MuiTypography-root': {
                                    fontSize: '1.2rem !important',
                                    fontWeight: 400,
                                    '&::first-letter': {
                                        textTransform: 'uppercase'
                                    },
                                    // textTransform: 'lowercase'
                                }
                            }}
                            primary={title}
                        />
                        {open ? <ExpandMore/> : <KeyboardArrowRight/>}
                    </>
                </Tooltip>
            </ListItemButton>
            <Collapse
                {...props}
                in={open}
                timeout="auto"
                sx={{
                    '&.MuiCollapse-root': {
                        minHeight: 'unset!important',
                    }
                }}
            >
                <List
                    className={'collapse-list'}
                    sx={{
                        pl: 2,
                    }}
                    component="div" disablePadding
                >
                    {children}
                </List>
            </Collapse>
        </>
    )
}

export default CollapseSideBarItem;
