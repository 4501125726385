import {zodResolver} from "@hookform/resolvers/zod";
import {NavigateNext, Refresh} from "@mui/icons-material";
import {Box, FormHelperText, Input, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useToast} from "../../../hooks";
import useError from "../../../hooks/useError";
import CrudForm from "../../../lib/components/Form/CrudForm";
import {ToastEnum} from "../../../types";
import {storage} from "../../../utils";
import {PasswordlessLoginFormSchema, PasswordlessLoginFormType, PhoneLoginFormType} from "../schemas";
import {useAskForPasswordlessLoginMutation, useLoginMutation} from "../services/authApi";
import {StyledIconButton} from "../../../lib/components/StyledIconButton";
import {useAuth} from "../hooks/useAuth";


interface IPasswordlessLoginFormProps {
    open: boolean
    onClose: () => void
    onSuccessfullLogin: () => void
    phoneNumber: string
}


export const PasswordlessLoginForm = ({
                                          open,
                                          onClose,
                                          onSuccessfullLogin,
                                          phoneNumber
                                      }: IPasswordlessLoginFormProps) => {
    const [otpChars, setOtpChars] = useState<string[]>(['', '', '', '', '', ''])
    const [login, {isLoading: isSubmitting, isError, error: loginError, isSuccess, data}] = useLoginMutation();
    const [maxAttempts, setMaxAttempts] = useState<number>(
        storage.get('max_attempts', 4)!
    )

    const [
        ressendOtpCode, {
            isError: isOtpRequestError,
            error: otpRequestError,
            isSuccess: isOtpRequestSuccess,
        }
    ] = useAskForPasswordlessLoginMutation();

    const {
        register,
        handleSubmit,
        formState,
        setValue,
        control,
        getValues,
        trigger
    } = useForm<PasswordlessLoginFormType>({
        resolver: zodResolver(PasswordlessLoginFormSchema)
    });
    const {errors} = formState;
    const {setErrors, getError, hasError} = useError(errors);
    const {toast} = useToast()
    const {user: auth} = useAuth()

    useEffect(() => {
        setErrors(errors)
    }, [errors, setErrors])

    useEffect(() => {
        setValue('phone_number', phoneNumber)
    }, [setValue, phoneNumber]);

    useEffect(() => {
        if (isSuccess && data) {
            storage.remember(
                'token',
                data.access_token,
                // ttl for 1 day
                60 * 24,
                true
            )
            storage.remember(
                'new_chat',
                1,
                //ttl for 5 minutes
                5 * 60 * 1000
            )
            toast("Vous êtes maintenant authentifié", ToastEnum.SUCCESS)
        }

        if (isError) {
            setErrors((
                loginError as any
            ).data.violations ?? {})
            toast("Une erreur est survénue lors de l'authentification", ToastEnum.ERROR)
        }
    }, [data, toast, isSuccess, isError, loginError, setErrors])

    useEffect(() => {
        if (isOtpRequestSuccess) {
            toast("Un message contenant votre de code de connexion à usage unique est envoyé au numéro que vous avez saisi. Veuillez saisir le code reçu dans les champs ci-dessous", ToastEnum.SUCCESS)

            // decrement max attempts
            setMaxAttempts(prevState => prevState - 1)
        }

        if (isOtpRequestError) {
            setErrors((
                otpRequestError as any
            ).data.violations ?? {})
            toast("Impossible d'envoyer votre code d'authentification à usage unique, merci de vérifier votre numéro et réessayer", ToastEnum.ERROR)
        }
    }, [toast, isOtpRequestSuccess, isOtpRequestError, otpRequestError, setErrors])

    useEffect(() => {
        storage.remember(
            'max_attempts',
            maxAttempts,
            // ttl for 15 minutes
            15 * 60 * 1000,
            true
        )
    }, [maxAttempts]);

    useEffect(() => {
        if (auth) {
            onSuccessfullLogin()
        }
    }, [auth, onSuccessfullLogin])

    const handleOtpCodeChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;

        const otpCharsCopy = [...otpChars];
        otpCharsCopy[index] = value;
        setOtpChars(otpCharsCopy);
        //     if all otp chars are filled, set the otp_code value
        if (otpCharsCopy.every(char => char.length > 0)) {
            setValue('otp_code', otpCharsCopy.join(''))
        }

        // prev gain focus if key is backspace
        if (value.length === 0) {
            const prevInput = document.getElementById(`otp_code_${index - 1}`) as HTMLInputElement;
            if (prevInput) {
                prevInput.focus();
            }
        }

        if (value.trim().length > 0) {
            // next input gain focus
            const nextInput = document.getElementById(`otp_code_${index + 1}`) as HTMLInputElement;
            if (nextInput) {
                nextInput.focus();
            }
        }
    }

    const onFormSubmit = (data: PhoneLoginFormType) => {
        login({
            data: data
        })
    }

    const onRequestResendOtpCode = useCallback(() => {
        ressendOtpCode({
            data: {
                phone_number: getValues('phone_number')
            }
        })
    }, [getValues, ressendOtpCode])

    const onPasteOtCode = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedValue = e.clipboardData.getData('text');
        if (pastedValue.length !== 6) {
            return;
        }
        for (let index = 0; index < pastedValue.length; index++) {
            const otpField = document.getElementById(`otp_code_${index}`) as HTMLInputElement;
            if (otpField) {
                otpField.value = pastedValue[index];
            }
        }
        setValue('otp_code', pastedValue)
    }, [setValue])

    return (
        <>
            <CrudForm
                open={open}
                onClose={onClose}
                title={'Vérification de votre numéro de téléphone'}
                isSubmitting={isSubmitting}
                isEdition={false}
                addButtonLabel={'Valider'}
                addButtonIcon={<NavigateNext/>}
                onSubmit={handleSubmit(onFormSubmit)}
            >
                <Input
                    disabled={false}
                    fullWidth
                    type={'hidden'}
                    inputProps={register('otp_code')}
                    hidden
                    sx={{
                        display: 'none',
                    }}
                />
                <TextField
                    disabled={true}
                    label="Ressaisissez votre numéro de téléphone"
                    variant="outlined"
                    fullWidth
                    type={'tel'}
                    margin="normal"
                    inputProps={register('phone_number')}
                    error={hasError('phone_number') || hasError('rate_limit')}
                    helperText={getError('phone_number') || getError('rate_limit') || 'Veuillez resaisir votre numéro de téléphone avec le préfixe international (ex: +226)'}
                />
                {maxAttempts > 0 && (
                    <Box
                        sx={{
                            mt: 2,
                            mb: 1,
                        }}
                    >
                        <StyledIconButton
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '.5rem',
                                mb: '.5rem',
                            }}
                            onClick={() => {
                                trigger('phone_number').then(() => {
                                    if (!hasError('phone_number')) {
                                        onRequestResendOtpCode()
                                    }
                                }).catch(() => {
                                })
                            }}
                        >
                            <Typography
                                variant={'body1'}
                                component={'span'}
                                sx={{
                                    fontSize: '.9rem',
                                    fontWeight: 'bold',
                                    display: 'block',
                                    color: 'text.primary',
                                }}
                            >
                                Renvoyer le code de vérification
                            </Typography>
                            <Refresh/>
                        </StyledIconButton>
                    </Box>
                )}
                <Box
                    sx={{
                        mt: 2,
                        mb: 1,
                    }}
                >
                    <Typography
                        variant={'body1'}
                        component={'span'}
                        sx={{
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            mb: '.5rem',
                            display: 'block',
                        }}
                    >
                        Code de vérification
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '.8rem',
                            alignItems: 'center',
                        }}
                    >
                        {Array.from({length: 6}).map((_, index) => (
                            <TextField
                                {...(index === 0) && {
                                    onPaste: onPasteOtCode
                                }}
                                key={index}
                                value={otpChars[index]}
                                id={`otp_code_${index}`}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleOtpCodeChange(index, e)}
                                disabled={false}
                                label={null}
                                variant="outlined"
                                type={'text'}
                                inputProps={{
                                    maxLength: 1,
                                }}
                                sx={{
                                    py: 0,
                                    '& input': {
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        fontWeight: 'bold',
                                        lineHeight: 0,
                                    }
                                }}
                                error={hasError('otp_code')}
                            />
                        ))}
                    </Box>
                    {hasError('otp_code') && (
                        <FormHelperText
                            error={true}
                        >{getError('otp_code')}</FormHelperText>
                    )}
                </Box>
            </CrudForm>
        </>
    )
}
