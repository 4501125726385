import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {GetItemsQueryArgs, IJsonResourceResponse,} from "../../../shared/types";
import {IFlashNotification} from "../entities";


export const flashApi = createApi({
    reducerPath: 'flashApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['FlashNotifications'],
    endpoints: (builder) => (
        {
            getFlashNotifications: builder.query<IJsonResourceResponse<IFlashNotification, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/notifications?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                    ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'FlashNotifications' as const,
                                    id,
                                }
                            )),
                            {type: 'FlashNotifications', id: 'LIST'},
                        ]
                    : [{type: 'FlashNotifications', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IFlashNotification, 'multiple'>) => results,
            }),
        }
    ),
});

export const {
    useGetFlashNotificationsQuery,
} = flashApi;
