import styled from "@emotion/styled";
import {IconButton} from "@mui/material";


export const StyledIconButton = styled(IconButton)(({theme}) => ({
    background: 'transparent!important',
    boder: 'none',
    '&::hover': {
        background: 'transparent!important',
        boder: 'none',
    }
}))
