import React, {useEffect} from 'react';
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import './assets/sass/app.scss';
import {PrivateRoute} from "./components/Route";
import PublicRoute from "./components/Route/PublicRoute";
import {authRoutes} from "./modules/auth/routes";
import {chatBotRoutes, privateChatRoutes} from "./modules/chatbot/routes";
import {useAppSelector} from "./redux/hooks/useRedux";
import {authSelector} from "./modules/auth/redux/selectors/authSelector";
import {useChat} from "./hooks";


function App() {
    const {user: auth} = useAppSelector(authSelector)
    const {realtime} = useChat()

    useEffect(() => {
        if (auth) {
            // realtime().connect(config.SOCKET_IO_BASE_URL, {
            //     transports: ['websocket'],
            //     query: {
            //         'token': storage.get('token') || ''
            //     }
            // })
        }
    }, [auth])

    return (

        <Router>
            <Routes>
                {Object.entries(privateChatRoutes).map(([_, route]) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <PrivateRoute permission={route.permission}>
                                    {route.component}
                                </PrivateRoute>
                            }
                        />
                    )
                })}
                {Object.entries(chatBotRoutes).map(([_, route]) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <PublicRoute>
                                    {route.component}
                                </PublicRoute>
                            }
                        />
                    )
                })}
                {Object.entries(authRoutes).map(([_, route]) => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <PublicRoute>
                                    {route.component}
                                </PublicRoute>
                            }
                        />
                    )
                })}
            </Routes>
        </Router>
    );
}

export default App;
