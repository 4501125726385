import {Box} from "@mui/material";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import LinearLoader from "../../../../lib/components/Loader/LinearLoader";
import {usePrivateChat} from "../../hooks";
import {useGetConversationQuery} from "../../services/conversationApi";
import {PrivateChatBox} from "../PrivateChatBox";
import {PrivateChatBodyProvider} from "../../../../providers/PrivateChatBodyProvider";


const PrivateConversation = () => {
    const {rememberCurrentConversation} = usePrivateChat()

    const {id} = useParams();

    const {isLoading: conversationLoading, data: conversation} = useGetConversationQuery({
        id: parseInt(id!)
    }, {
        refetchOnMountOrArgChange: true,
    })

    useEffect(() => {
        if (conversation) {
            rememberCurrentConversation(conversation)
        }
    }, [conversation, rememberCurrentConversation])

    if (conversationLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    gap: '1rem',
                    width: '100%',
                    ml: '320px',
                    height: '100%'
                }}
            >
                <LinearLoader/>
            </Box>
        )
    }

    return (
        <>

            <PrivateChatBodyProvider>
                <PrivateChatBox/>
            </PrivateChatBodyProvider>
        </>
    )
}

export default PrivateConversation
