import {createContext} from "react";


export interface IMessage {
    readonly id: string;
    readonly content: string;
    readonly createdAt: string;
    isMine: boolean;
    type: 'text' | 'image' | 'audio' | 'video' | 'file';
    username?: string;
    attachment?: IAttachment | null
}


export interface IAttachment {
    src: string;
    name?: string;
    size?: number;
}


interface ChatProviderProps {
    messages: IMessage[];
    addMessage: (message: IMessage) => void;
    addMessages: (messages: IMessage[]) => void;
    mergeWithLocalMessages: (onlineMessages: IMessage[]) => void;
    isTyping: boolean;
    startTyping: (isTyping: boolean) => void;
    isRecording: boolean;
    startRecording: (isRecording: boolean) => void;
    realtime: () => any
}


export const ChatContext = createContext<ChatProviderProps | null>(null);
