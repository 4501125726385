import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks/useRedux";
import {logout} from "../redux/features/authSlice";
import {authSelector} from "../redux/selectors/authSelector";


export const useAuth = () => {
    const dispatch = useAppDispatch();
    const {user} = useAppSelector(authSelector);
    const [isAuthenticated] = useState(!!user)
    
    const logoutHandler = () => {
        dispatch(logout())
    }
    
    return {
        user,
        isAuthenticated,
        logout: logoutHandler
    }
    
}
