import React, {PropsWithChildren} from "react";


import {Outlet} from "react-router-dom";
import {ChatLayout} from "../Layouts";


type IPublicRouteProps = PropsWithChildren<{}>;


const PublicRoute = ({children}: IPublicRouteProps) => {
    return children ? <ChatLayout>{children}</ChatLayout> : <Outlet/>
}

export default PublicRoute
