import React, {useCallback, useEffect} from "react";
import {useChat, useToast} from "../../../../hooks";
import useError from "../../../../hooks/useError";
import {ToastEnum} from "../../../../types";
import {formatDate} from "../../../../utils";
import {useAskQuestionToBotMutation} from "../../services/messageApi";
import {ChatBody} from "../ChatBody";
import {ChatBotCTA} from "../ChatBotCTA";
import {ChatInput} from "../ChatInput";

export const BotChatBox = () => {
    const {addMessage, startTyping,} = useChat()
    const {toast} = useToast()
    const {setErrors} = useError({});

    const [
        askQuestionToBot, {
            isError: hasAskQuestionToBotError,
            isSuccess: isAskQuestionToBotSuccess,
            error: askQuestionToBotError,
            data: askQuestionToBotMessage,
        }
    ] = useAskQuestionToBotMutation()

    useEffect(() => {
        if (isAskQuestionToBotSuccess && askQuestionToBotMessage) {
            startTyping(false);
            addMessage({
                id: askQuestionToBotMessage.created_at as unknown as string,
                type: askQuestionToBotMessage.type,
                isMine: askQuestionToBotMessage.isMine,
                createdAt: formatDate(new Date(askQuestionToBotMessage.created_at)),
                content: askQuestionToBotMessage.content,
            })
        }

        if (hasAskQuestionToBotError && askQuestionToBotError) {
            toast('Erreur lors de l\'envoie du message', ToastEnum.ERROR)
            setErrors((
                askQuestionToBotError as any
            ).data.violations ?? {})
            startTyping(false);
        }
    }, [askQuestionToBotError, hasAskQuestionToBotError, askQuestionToBotMessage, isAskQuestionToBotSuccess, setErrors, toast])
    
    
    const handleOnSendMessage = useCallback((message: string) => {
        addMessage({
            content: message,
            isMine: true,
            createdAt: formatDate(new Date()),
            type: 'text',
            id: String(Date.now()),
        })
        
        startTyping(true);
        
        askQuestionToBot({
            data: {
                content: message,
            }
        })
    }, [askQuestionToBot, addMessage])
    
    return (
        <>
            <ChatBody/>
            <ChatBotCTA/>
            <ChatInput
                onSend={handleOnSendMessage}
            />
        </>
    )
}
