import {AES} from "crypto-js";
import Utf8 from "crypto-js/enc-utf8";
import {fingerprint} from "../Utils";


export class Encrypt {
    private readonly algorithm: string
    private readonly key: string
    
    constructor(key: string = '@', algorithm: string = 'aes-256-cbc') {
        this.algorithm = algorithm
        this.key = fingerprint.uniqueKey(key)
    }
    
    public encrypt(text: string): string {
        // use crypto-js to encrypt data
        const cipher = AES.encrypt(text, this.key)
        return cipher.toString()
    }
    
    public decrypt(text: string): string {
        // use crypto-js to decrypt data
        const bytes = AES.decrypt(text, this.key)
        return bytes.toString(Utf8)
    }
}