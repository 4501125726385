import {ThemeProvider} from "@mui/material";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import App from './App';
import {StorageProvider} from "./packages/storage";
import {ChatProvider} from "./providers/ChatProvider";
import {MenuProvider} from "./providers/MenuProvider";
import {store} from "./redux";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import theme from "./theme";
import socketIO from "socket.io-client";
import {config} from "./config";
import {storage} from "./utils";

// add io to window type
declare global {
    interface Window {
        io: any
    }
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const io = socketIO(config.SOCKET_IO_BASE_URL, {
    transports: ['websocket'],
    query: {
        ...storage.exists('token') && {
            'token': storage.get('token')
        }
    }
});

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ChatProvider io={io}>
                    <StorageProvider>
                        <MenuProvider>
                            <App/>
                        </MenuProvider>
                    </StorageProvider>
                </ChatProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

