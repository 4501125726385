import {z} from "zod";


export const ConversationRequestSchema = z.object({
//     gender : 'MASCULIN', 'FEMININ'
    gender: z.enum(['MASCULIN', 'FEMININ'], {
        invalid_type_error: 'Le genre doit être l\'un des suivants : "MASCULIN" ou "FEMININ"',
        required_error: 'Le genre est obligatoire',
    }),
    username: z.string().min(3, {
        message: 'Le nom doit contenir au moins 3 caractères',
    }).max(100, {
        message: 'Le nom doit contenir au maximum 100 caractères',
    }),
    subject: z.string().min(2, {
        message: 'Le sujet doit contenir au moins 2 caractères',
    }).max(255, {
        message: 'Le sujet doit contenir au maximum 255 caractères',
    }),
    // +226 70 00 00 00 || 70 00 00 00 || 70000000 || +22670000000 || 0022670000000
    // phone_number: z.string().regex(/^(00226|\+226|0)?[0-9]{8}$/, {
    //     message: 'Le numéro de téléphone doit être au format +226 70 00 00 00',
    // }),
    rubric_id: z.string({
            required_error: 'La rubrique est obligatoire',
        }
    ).regex(/^\d+$/, "La rubrique doit est invalide"),
})

export type ConversationRequestFormType = z.infer<typeof ConversationRequestSchema>;

export const ContinueConversationSchema = z.object({
    reference: z.string({
        required_error: 'La référence est obligatoire',
    }).min(36, {
        message: 'La référence doit contenir au moins 36 caractères',
    }).max(36, {
        message: 'La référence doit contenir au maximum 36 caractères',
    }),
})

export type ContinueConversationFormType = z.infer<typeof ContinueConversationSchema>;

export const RatingConversationSchema = z.object({
    rate: z.number({
        required_error: 'La note est obligatoire',
    }).min(1, {
        message: 'La note doit être moins 1',
    }).max(5, {
        message: 'La note doit être au plus que 5',
    }),
    feedback: z.string({
        required_error: 'Le commentaire est obligatoire',
    }).min(3, {
        message: 'Le commentaire doit contenir au moins 3 caractères',
    }).max(255, {
        message: 'Le commentaire doit contenir au maximum 255 caractères',
    }),
})

export type RatingConversationFormType = z.infer<typeof RatingConversationSchema>;
