import {zodResolver} from "@hookform/resolvers/zod/dist/zod";
import {SkipNext} from "@mui/icons-material";
import {Button, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useConversation, useToast} from "../../../../hooks";
import useError from "../../../../hooks/useError";
import CrudForm from "../../../../lib/components/Form/CrudForm";
import {ConversationStatus} from "../../../../redux/conversation/types";
import {ToastEnum} from "../../../../types";
import {ContinueConversationFormType, ContinueConversationSchema} from "../../schemas";
import {useGetConversationRequestQuery} from "../../services/conversationRequestApi";
import {IContinueConversationFormProps} from "./props";


export const ContinueConversationForm = ({open, onClose}: IContinueConversationFormProps) => {
    
    const [reference, setReference] = useState<string | null>(null)
    
    const {
        updateConversationStatus,
        rememberConversationRef,
        updateConversationAgentName,
        updateConversationId
    } = useConversation()
    
    const {register, handleSubmit, formState, reset} = useForm<ContinueConversationFormType>({
        resolver: zodResolver(ContinueConversationSchema)
    });
    
    const {errors} = formState;
    const {setErrors, getError, hasError} = useError(errors);
    
    const {
        data: conversationRequest,
        isLoading: isConversationRequestLoading,
        isError,
        error
    } = useGetConversationRequestQuery(reference!, {
        skip: !reference,
    })
    
    const {toast} = useToast()
    
    useEffect(() => {
        setErrors(errors)
    }, [errors, setErrors])
    
    useEffect(() => {
        if (isError && error) {
            console.error(error)
            toast((
                error as any
            ).data.message, ToastEnum.ERROR)
        }
    }, [isError, error, toast])
    
    useEffect(() => {
        if (conversationRequest) {
            rememberConversationRef(conversationRequest.reference)
            if (conversationRequest.conversation_id) {
                updateConversationId(conversationRequest.conversation_id)
            }
            if (conversationRequest.agent_name) {
                updateConversationAgentName(conversationRequest.agent_name)
            }
            updateConversationStatus(conversationRequest.state as ConversationStatus)
            reset()
            toast("La conversation a été chargée avec succès", ToastEnum.SUCCESS)
            onClose()
            
            const timer = setTimeout(() => window.location.reload(), 1000)
            
            return () => {
                clearTimeout(timer)
            }
        }
    }, [conversationRequest])
    
    
    const onFormSubmit = (data: ContinueConversationFormType) => {
        setReference(data.reference)
    }
    
    return (
        <>
            <CrudForm
                open={open}
                onClose={onClose}
                title={'Poursuivre une conversation'}
                isSubmitting={false}
                isEdition={false}
                onSubmit={handleSubmit(onFormSubmit)}
                onRenderSubmitButton={() => (
                    <>
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isConversationRequestLoading}
                        >
                            <SkipNext/>
                            Continuer
                        </Button>
                    </>
                )}
            >
                <TextField
                    label="Référence de la conversation"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={hasError('reference')}
                    helperText={getError('reference')}
                    {...register('reference')}
                />
            </CrudForm>
        </>
    )
}
