import {ICacheable} from "../Cacheable";
import {SecureStorage} from "../SecureStorage/SecureStorage";


export class JSONStorage implements ICacheable {
    private readonly ttl: number
    private storage: SecureStorage;
    
    // ttl in minutes, default 30 minutes
    constructor(ttl: number = 30) {
        this.ttl = ttl
        this.storage = new SecureStorage();
    }
    
    public remember<T>(key: string, data: T, ttl: number = this.ttl, watch: boolean = false) {
        this.storage.remember(key, data, watch);
        // generate a timestamp
        this.storage.remember(key + '_timestamp', new Date().toString(), watch);
        
        // remember the ttl
        this.storage.remember(key + '_ttl', ttl.toString(), watch);
    }
    
    public get<T>(key: string, defaultValue: T| null = null): T | null {
        if (this.exists(key)) {
            if (this.isExpired(key)) {
                this.forget(key)
                return defaultValue
            }
            return this.storage.get<T>(key, defaultValue);
        }
        return defaultValue
    }
    
    public forget(key: string | Array<string>) {
        if (Array.isArray(key)) {
            key.forEach(k => {
                if (this.exists(k)) {
                    this.storage.forget(k);
                    this.storage.forget(k + '_timestamp');
                    this.storage.forget(k + '_ttl');
                }
            })
        } else if (this.exists(key)) {
            this.storage.forget(key);
            this.storage.forget(key + '_timestamp');
            this.storage.forget(key + '_ttl');
        }
    }
    
    public exists(key: string) {
        return this.storage.has(key);
    }
    
    public isExpired(key: string) {
        if (
            !this.exists(key + '_timestamp') ||
            !this.exists(key + '_ttl')
        ) {
            return true;
        }
        
        const timestamp = this.getTimestamp(key);
        
        const ttl = this.getTtl(key);
        
        if (timestamp === null || ttl === null) {
            return true;
        }
        
        const diff = (Date.now() - timestamp.getTime()) / 1000
        
        return diff > ttl * 60
    }
    
    public watch<T>(key: string, callback: (data: T | null) => void) {
        // watch for changes on the key in local storage
        this.storage.watch<T>(key, callback);
    }
    
    public forgot(key: string): boolean {
        if (this.exists(key) && this.isExpired(key)) {
            this.forget(key)
            return true
        } else if (this.exists(key)) {
            return false
        }
        return true
    }
    
    private getTimestamp(key: string): Date | null {
        if (this.exists(key + '_timestamp')) {
            return new Date(this.storage.get<string>(key + '_timestamp') || '');
        }
        return null;
    }
    
    private getTtl(key: string): number | null {
        if (this.exists(key + '_ttl')) {
            return parseInt(this.storage.get<string>(key + '_ttl') || '');
        }
        return null;
    }
}
