import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {initialHttpErrorState} from "./state";
import {IHttpErrorState} from "./types";


const httpErrorSlice = createSlice({
    name: 'httpError',
    initialState: initialHttpErrorState,
    reducers: {
        updateHttpError: (state, action: PayloadAction<IHttpErrorState>) => {
            state.code = action.payload.code;
            state.message = action.payload.message;
            state.status = action.payload.status;
        },
        clearHttpError: () => initialHttpErrorState,
    }
})

export const {updateHttpError, clearHttpError} = httpErrorSlice.actions;

export default httpErrorSlice.reducer;