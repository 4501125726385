import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {IJsonResourceResponse, PostItemArgs} from "../../../shared/types";
import {IConversationRequest} from "../entities";


export const conversationRequestApi = createApi({
    reducerPath: 'conversationRequestApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['ConversationRequests'],
    endpoints: (builder) => (
        {
            createConversationRequest: builder.mutation<IConversationRequest, PostItemArgs<IConversationRequest>>({
                query: ({data}) => (
                    {
                        url: '/conversation-requests',
                        method: 'POST',
                        data: data,
                    }
                ),
                invalidatesTags: [{type: 'ConversationRequests', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IConversationRequest, 'single'>) => results.item!,
            }),
            getConversationRequest: builder.query<IConversationRequest, string>({
                query: (reference) => (
                    {
                        url: `/conversation-requests/references/${reference}`,
                        method: 'GET',
                    }
                ),
                transformResponse: (results: IJsonResourceResponse<IConversationRequest, 'single'>) => results.item!,
            }),
        }
    ),
});

export const {
    useGetConversationRequestQuery,
    useCreateConversationRequestMutation,
} = conversationRequestApi;
