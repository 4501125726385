import {CircularProgress} from '@mui/material'
import {CSSProperties} from "react";


interface ICircularLoaderProps {
    w?: CSSProperties['width']
    h?: CSSProperties['height']
}


export default function CircularLoader({w, h}: ICircularLoaderProps) {
    return <CircularProgress sx={{
        width: (w && typeof w === 'number') ? `${w}px!important` : (w || '40px'),
        height:(h && typeof h === 'number') ? `${h}px!important` : (h || '40px'),
        mr: 1.5,
    }}/>
}
