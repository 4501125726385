import {createApi} from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {PostItemArgs} from "../../../shared/types";
import {IPasswordlessLoginResponse, IPasswordlessRequest, IPhoneLoginRequest} from "../entities";
import {profileApi} from "./profileApi";


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Auth'],
    endpoints: (builder) => (
        {
            login: builder.mutation<IPasswordlessLoginResponse, Omit<PostItemArgs<IPhoneLoginRequest>, 'parentId'>>({
                query: ({data}) => {
                    return {
                        url: `/auth/passwordless/login`,
                        method: 'POST',
                        credentials: 'include',
                        data
                    }
                },
                async onQueryStarted(args, {dispatch, queryFulfilled}) {
                    await queryFulfilled
                    await dispatch(profileApi.endpoints.getProfile.initiate(null))
                }
            }),
            askForPasswordlessLogin: builder.mutation<IPasswordlessLoginResponse, Omit<PostItemArgs<IPasswordlessRequest>, 'parentId'>>({
                query: ({data}) => {
                    return {
                        url: `/auth/passwordless/otp`,
                        method: 'POST',
                        credentials: 'include',
                        data
                    }
                },
                transformResponse: (result: { item: IPasswordlessLoginResponse }) => result.item,
            }),
        }
    ),
})

export const {
    useLoginMutation,
    useAskForPasswordlessLoginMutation,
} = authApi
