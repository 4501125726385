import CloseIcon from "@mui/icons-material/Close";
import {Box, IconButton} from "@mui/material";
import React from "react";
import {formatFileSize} from "../Message";
import {IFileInfoProps} from "./props";


export const FileInfo = ({name, size, onClear, type}: IFileInfoProps) => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                minWidth: 0,
                '& p': {
                    margin: 0,
                    fontSize: '12px',
                },
                gap: '.5rem',
                marginBottom: '.5rem',
                background: 'rgba(0,0,0,.02)',
                padding: '.5rem',
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'center',
                        gap: '.5rem',
                    }}
                >
                    <p style={{
                        fontWeight: 700,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                    }}>
                        {name}
                    </p>
                    <p style={{
                        fontWeight: 400,
                    }}>
                        {formatFileSize(size)}
                    </p>
                </Box>
                <IconButton
                    onClick={onClear}
                    size="small"
                >
                    <CloseIcon/>
                </IconButton>
            </Box>
        </>
    )
}
