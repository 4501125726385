import {PropsWithChildren} from "react";
import {FlashContext} from "../context/FlashContext";
import {useGetFlashNotificationsQuery} from "../modules/flashes/services/flashApi";


interface FlashProviderProps extends PropsWithChildren {}


export const FlashProvider = ({children}: FlashProviderProps) => {
    
    const {data, isLoading} = useGetFlashNotificationsQuery({
        limit: 1000,
        page: 1,
    })
    
    if (isLoading) {
        return (
            <FlashContext.Provider value={{hasFlash: false, flashes: []}}>
                {children}
            </FlashContext.Provider>
        )
    }
    
    return (
        <FlashContext.Provider value={{
            hasFlash: !!(
                data && data.items!.length > 0
            ),
            flashes: data ? data.items! : []
        }}
        >
            {children}
        </FlashContext.Provider>
    );
}
