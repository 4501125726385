import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {storage} from "../../utils";
import {ConversationStatus, IConversationState} from "./types";


const conversationId = storage.get<string>('conversation_id')
const conversationRef = storage.get('conversation_ref')
const conversationStatus = storage.get('conversation_status')
const conversationAgentName = storage.get('conversation_agent_name')
const supportId = storage.get('support_id')


const initialState: IConversationState = {
    status: conversationStatus as ConversationStatus,
    reference: conversationRef,
    agent_name: conversationAgentName,
    is_archive: false,
    conversation_id: conversationId ? parseInt(conversationId, 10) : null,
    supportId: supportId
} as IConversationState

const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        setConversationRef(state, action: PayloadAction<string>) {
            state.reference = action.payload;
        },
        setConversationStatus(state, action: PayloadAction<ConversationStatus>) {
            state.status = action.payload;
        },
        setConversationAgentName(state, action: PayloadAction<string>) {
            state.agent_name = action.payload;
        },
        
        setConversationId(state, action: PayloadAction<number>) {
            state.conversation_id = action.payload;
        },
        
        archiveConversation(state) {
            state.is_archive = true;
        },
        
        setConversationSupportId(state, action: PayloadAction<IConversationState['supportId']>) {
            state.supportId = action.payload
        },
        
        clearConversation(state) {
            state.reference = null;
            state.status = null;
            state.agent_name = null;
            state.conversation_id = null;
            state.is_archive = false;
            state.supportId = null
            storage.forget([
                'conversation_ref',
                'conversation_status',
                'conversation_agent_name',
                'conversation_id',
                'support_id'
            ])
        }
    }
})

export const {
    setConversationRef,
    setConversationStatus,
    setConversationAgentName,
    setConversationId,
    archiveConversation,
    clearConversation,
    setConversationSupportId
} = conversationSlice.actions;

export default conversationSlice.reducer;
