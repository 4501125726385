import React, {PropsWithChildren, useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useAuth} from "../../modules/auth/hooks/useAuth";
import {ChatLayout} from "../Layouts";


export function PrivateRoute({children, permission, wrap = true}: IPrivateRouteProps) {
    const {isAuthenticated, user: auth} = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/')
        }
    }, [isAuthenticated, navigate])
    
    if (!isAuthenticated || auth?.role === 'ROLE_AGENT') {
        return null;
    }
    
    return (
        children ? (
            wrap ? <ChatLayout>{children}</ChatLayout> : <>{children}</>
        ) : <Outlet/>
    )
}


interface IPrivateRouteProps extends PropsWithChildren {
    permission?: string
    wrap?: boolean
}
