import * as React from "react";

export const TextLoader = ({message}: { message?: string }) => {
    return (
        <>
            <div>
                {message ? message : "Chargement..."}
            </div>
        </>
    )
}