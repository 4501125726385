import {PropsWithChildren, useCallback, useEffect, useState} from "react";
import {ChatContext, IMessage} from "../context/ChatContext";
import {storage} from "../utils";


const cacheMessages: IMessage[] = storage.get('messages') || []
export const ChatProvider = ({children, io}: { io: any } & PropsWithChildren) => {
    const [messages, setMessages] = useState<IMessage[]>(cacheMessages)
    const [isTyping, setIsTyping] = useState<boolean>(false)
    const [isRecording, setIsRecording] = useState<boolean>(false)

    useEffect(() => {
        storage.remember('messages', messages)
    }, [messages])

    const addMessage = useCallback((message: IMessage) => {
        setMessages((messages) => {
            return [...messages, message]
        })
    }, []);

    const addMessages = useCallback((messages: IMessage[]) => {
        setMessages((prevMessages) => {
            return [...messages, ...prevMessages]
        })
    }, [])

    const mergeWithLocalMessages = useCallback((onlineMessages: IMessage[]) => {
        const newMessages = [...messages, ...onlineMessages]
        const uniqueMessages = newMessages.filter((message, index) => {
            return messages.findIndex((m) => m.id === message.id) === index
        })
        setMessages(uniqueMessages)
    }, [])

    const realtime = useCallback(() => io, [])

    const startTyping = useCallback((flag: boolean) => {
        setIsTyping(flag)
    }, [])

    const startRecording = useCallback((flag: boolean) => {
        setIsRecording(flag)
    }, [])

    const context = {
        messages,
        addMessage,
        isTyping,
        startTyping,
        isRecording,
        startRecording,
        addMessages,
        realtime,
        mergeWithLocalMessages,
    }

    return (
        <ChatContext.Provider value={context}>
            {children}
        </ChatContext.Provider>
    )
}
