import {Close, Menu} from "@mui/icons-material";
import {Avatar, Box, Typography} from "@mui/material";
import React from "react";
import Logo from "../../../../assets/images/logo";
import {useFlash, useMenuBreakPoints} from "../../../../hooks";
import {useMenu} from "../../../../hooks/useMenu";
import {Flashable} from "../../../../lib/components/Flashable";
import {StyledIconButton} from "../../../../lib/components/StyledIconButton";


export const ChatHeader = () => {
    const {tablet} = useMenuBreakPoints()
    const {flashes} = useFlash()
    const {open, toggleMenu} = useMenu()

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100px',
            padding: '0 16px',
            background: 'rgba(255, 255, 255)',
            position: tablet ? 'fixed' : 'relative',
            ...tablet && {
                top: '0',
                left: '0',
                zIndex: 10,
                width: '100%',
            }
        }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    justifyContent: 'space-between',
                    width: '100%',
                    // mt: {
                    //     xs: '1.5rem',
                    // },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Avatar
                        src={Logo}
                        sx={{
                            width: '90px',
                            height: '90px',
                            borderRadius: 0,
                        }}
                    />
                    <Typography
                        variant={'h6'}
                        sx={{
                            fontSize: {
                                xs: '.9rem',
                                sm: '1.2rem'
                            },
                            fontWeight: 600,
                            color: '#023766',
                            textAlign: 'center',
                        }}
                    >
                        Brigade Numérique de Veille, d'Alerte et d'Assistance
                    </Typography>
                </Box>
                {tablet && (
                    <>
                        <StyledIconButton
                            onClick={toggleMenu}
                            sx={{
                                color: '#023766',
                                position: 'relative',
                                zIndex: 1000,
                                '& svg': {
                                    fontSize: '2.8rem',
                                }
                            }}
                        >
                            {open ? <Close/> : <Menu/>}
                        </StyledIconButton>
                    </>
                )}
            </Box>
            {(
                <Box
                    sx={{
                        position: 'absolute',
                        top: '100px',
                        left: '0',
                        zIndex: 1,
                        width: '100%',
                        background: '#023766',
                    }}
                >
                    <Flashable flashes={flashes}/>
                </Box>
            )}
        </Box>
    )
}
