import styled from "@emotion/styled";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';
import {Controller} from 'react-hook-form';
import {IFormSelectProps} from "./props";


const FormControlWrapper = styled(FormControl)({
    margin: '0.5rem 0',
});

const FormSelect = ({
    name,
    label,
    control,
    error,
    isInvalid,
    options,
    valueKey,
    labelKey,
    placeholder,
    disabled,
    register,
    defaultValue,
}: IFormSelectProps) => {
    return (
        <FormControlWrapper variant="outlined" fullWidth error={isInvalid}>
            <InputLabel>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <Select
                        defaultValue={defaultValue}
                        disabled={disabled}
                        {...field}
                        inputRef={register}
                        label={label}>
                        <MenuItem value="">
                            <em>{placeholder}</em>
                        </MenuItem>
                        {options.map((item, index) => (
                            <MenuItem key={index} value={item[valueKey]}>
                                {item[labelKey]}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            {isInvalid && <FormHelperText>{error}</FormHelperText>}
        </FormControlWrapper>
    );
}

export default FormSelect
