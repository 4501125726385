import React from "react";
import LinearLoader from "../../lib/components/Loader/LinearLoader";

export const SplashScreen = () => {
    return (
        <>
            <LinearLoader/>
        </>
    )
}

export default SplashScreen;
