import {Avatar, Box} from "@mui/material";
import React from "react";
import {IMessage} from "./props";


export const Message = ({
                            children,
                            position,
                            avatar,
                            bg = '#F7FBFF',
                            color = '#192A3E'
                        }: IMessage) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
                gap: '1rem',
                margin: '1rem 0',
            }}
        >
            {position === 'left' && (
                <Avatar
                    src={avatar}
                    sx={{
                        width: '42px',
                        height: '42px',
                        borderRadius: 0,
                    }}
                />
            )}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                padding: '15px',
                gap: '.8rem',
                maxWidth: '350px',
                width: '100%',
                background: bg,
                borderRadius: '12px',
                minHeight: '47px',
                position: 'relative',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    display: 'inline-block',
                    height: 0,
                    width: 0,
                    zIndex: -1,
                    ...position === 'left' ? {
                        borderTop: '18px solid transparent',
                        borderRight: `15px solid ${bg}`,
                        borderBottom: '15px solid transparent',
                        left: '-13px',
                    } : {
                        borderTop: '15px solid transparent',
                        borderLeft: `15px solid ${bg}`,
                        borderBottom: '18px solid transparent',
                        right: '-13px',
                    }
                }
            }}
            >
                {children}
            </Box>
            {position === 'right' && (
                <Avatar
                    src={avatar}
                    sx={{
                        width: '42px',
                        height: '42px',
                        borderRadius: 0,
                    }}
                />
            )}
        </Box>
    )
}
