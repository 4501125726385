import {zodResolver} from "@hookform/resolvers/zod";
import {Refresh} from "@mui/icons-material";
import {Box, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import {useCallback, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {useChat, useConversation, useToast} from "../../../../hooks";
import useError from "../../../../hooks/useError";
import CrudForm from "../../../../lib/components/Form/CrudForm";
import {StyledIconButton} from "../../../../lib/components/StyledIconButton";
import {ConversationStatus} from "../../../../redux/conversation/types";
import {ToastEnum} from "../../../../types";
import {formatDate} from "../../../../utils";
import {useAuth} from "../../../auth/hooks/useAuth";
import {IConversationRequest} from "../../entities";
import {ConversationRequestFormType, ConversationRequestSchema} from "../../schemas";
import {useCreateConversationRequestMutation} from "../../services/conversationRequestApi";
import {useGetRubricsQuery} from "../../services/rubricApi";
import FormSelect from "../FormSelect/FormSelect";
import {IConversationRequestFormProps} from "./props";


export const AskConversationForm = ({open, onClose, onSuccess}: IConversationRequestFormProps) => {
    const {user: auth} = useAuth()
    const {data: rubrics, isLoading: isRubricsLoading, refetch: refetchRubrics} = useGetRubricsQuery({
        page: 1,
        limit: 1000,
    })
    const {register, handleSubmit, formState, reset, control} = useForm<ConversationRequestFormType>({
        resolver: zodResolver(ConversationRequestSchema),
        defaultValues: {
            gender: auth?.gender ?? 'MASCULIN',
            username: auth?.full_name ?? '',
        },
    });
    const {errors} = formState;
    const {setErrors, getError, hasError} = useError(errors);
    const {toast} = useToast()
    const {updateConversationStatus} = useConversation()
    const {addMessage} = useChat()

    const [
        createConversationRequest, {
            isLoading: isSubmitting,
            isError: hasCreationError,
            error: creationError,
            isSuccess: isCreatedWithSuccess,
            data: conversationRequest
        }
    ] = useCreateConversationRequestMutation()

    useEffect(() => {
        if (!auth) {
            onClose()
        }
    }, [auth, onClose])

    useEffect(() => {
        setErrors(errors)
    }, [errors, setErrors])

    const resetForm = useCallback(() => {
        reset()
    }, [reset])

    useEffect(() => {
        if (isCreatedWithSuccess) {
            onClose()
            onSuccess()
        }
    }, [isCreatedWithSuccess, onClose, onSuccess])

    useEffect(() => {
        if (isCreatedWithSuccess) {
            toast(
                'Votre demande a été envoyée avec succès',
                ToastEnum.SUCCESS
            )
            resetForm()
        }
        if (hasCreationError && creationError) {
            toast(
                'Une erreur est survenue lors de la création de la demande de conversation',
                ToastEnum.ERROR
            )
            setErrors((
                creationError as any
            ).data.violations ?? {})
        }

    }, [isCreatedWithSuccess, isSubmitting, hasCreationError, creationError, setErrors, resetForm, toast])

    useEffect(() => {
        if (conversationRequest) {
            updateConversationStatus(conversationRequest.state as ConversationStatus)
            addMessage({
                content: `
                    Bonjour <span style="font-weight: bold">${conversationRequest.username}</span>, votre demande de conversation a été envoyée avec succès. <br>
                    Patientez quelques instants, un agent vous contactera dans les plus brefs délais.
                `,
                isMine: false,
                createdAt: formatDate(new Date(conversationRequest.created_at)),
                type: 'text',
                id: String(conversationRequest.id),
            })
        }

    }, [conversationRequest])

    const handleOnClose = useCallback(() => {
        onClose()
        onSuccess()
    }, [onClose, onSuccess])

    const onFormSubmit = (data: ConversationRequestFormType) => {

        createConversationRequest({
            data: {
                ...data,
                phone_number: auth!.username,
            } as unknown as Partial<IConversationRequest>
        })
    }

    return (
        <>
            <CrudForm
                open={open}
                onClose={handleOnClose}
                title={'Demande de conversation'}
                isSubmitting={isSubmitting}
                isEdition={false}
                onSubmit={handleSubmit(onFormSubmit)}
            >
                {hasError('message') &&
                    <FormHelperText error={true}>
                        {getError('message')}
                    </FormHelperText>
                }
                {!auth!.full_name && (
                    <TextField
                        disabled={isSubmitting}
                        label="Nom complet"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        inputProps={register('username')}
                        error={hasError('username')}
                        helperText={getError('username')}
                    />
                )}
                {!auth!.gender && (
                    <FormSelect
                        name={'gender'}
                        label={'Votre genre'}
                        control={control}
                        disabled={isSubmitting}
                        isInvalid={hasError('gender')}
                        error={getError('gender')}
                        placeholder={'Choisissez votre genre'}
                        options={[
                            {value: 'MASCULIN', label: 'Homme'},
                            {value: 'FEMININ', label: 'Femme'},
                        ]}
                        valueKey={'value'}
                        labelKey={'label'}
                        register={register('gender')}
                    />
                )}
                {rubrics && (
                    <Box sx={{
                        my: 2,
                        maxWidth: 500,
                        width: '100%',
                        display: 'flex',
                        gap: '.5rem'
                    }}>
                        <FormControl
                            fullWidth
                        >
                            <InputLabel
                                variant={'outlined'}
                                id="rubric_id"
                            >
                                Rubrique
                            </InputLabel>
                            <Controller
                                name="rubric_id"
                                control={control}
                                defaultValue={""}
                                render={({field}) => (
                                    <Select
                                        {...field}
                                        error={hasError('rubric_id')}
                                        labelId="rubric_id"
                                        label="Rubrique"
                                        disabled={isSubmitting}
                                        input={<OutlinedInput label="Rubrique"/>}
                                    >
                                        <MenuItem value={''}>
                                            <em>Sélectionnez la rubrique</em>
                                        </MenuItem>
                                        {rubrics.items!.map(rubric => (
                                            <MenuItem
                                                key={rubric.id}
                                                value={String(rubric.id)}
                                            >
                                                {rubric.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {hasError('rubric_id') &&
                                <FormHelperText error={true}>
                                    {getError('rubric_id')}
                                </FormHelperText>}
                        </FormControl>
                        <StyledIconButton
                            onClick={() => refetchRubrics()}
                        >
                            <Refresh/>
                        </StyledIconButton>
                    </Box>
                )}
                <TextField
                    disabled={isSubmitting}
                    label="Sujet de la conversation"
                    variant="outlined"
                    fullWidth
                    multiline
                    margin="normal"
                    inputProps={register('subject')}
                    error={hasError('subject')}
                    helperText={getError('subject')}
                />
            </CrudForm>
        </>
    )
}
