import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {IUser} from "../entities";
import {authenticate} from "../redux/features/authSlice";


export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Profile'],
    endpoints: (builder) => ({
        getProfile: builder.query<IUser, null>({
            query: () => {
                return {
                    url: `/auth/profile`,
                    method: 'GET',
                    credentials: 'include',
                }
            },
            transformResponse: (result: { item: IUser }) => result.item,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                const {data} = await queryFulfilled
                dispatch(
                    authenticate(data)
                )
            }
        }),
    }),
})

export const {
    useGetProfileQuery,
} = profileApi;
