import {IMessage} from "../modules/chatbot/entities";
import {PropsWithChildren, useCallback, useState} from "react";
import {PrivateChatBodyContext, PrivateChatBodyContextProps} from "../context/PrivateChatBodyContext";

interface IChatBodyProviderProps extends PropsWithChildren {
}

export const PrivateChatBodyProvider = ({children}: IChatBodyProviderProps) => {

    const [messages, setMessages] = useState<IMessage[]>([])

    const addMessage = useCallback((message: IMessage) => {
        setMessages((messages) => {
            return [...messages, message]
        })
    }, [])

    const addMessages = useCallback((messages: IMessage[]) => {
        setMessages((prevMessages) => {
            return [...messages, ...prevMessages]
        })
    }, [])

    const initMessages = useCallback((messages: IMessage[]) => {
        setMessages(messages)
    }, [])

    const context = {
        messages,
        addMessage,
        addMessages,
        initMessages,
    } as PrivateChatBodyContextProps

    return (
        <PrivateChatBodyContext.Provider value={context}>
            {children}
        </PrivateChatBodyContext.Provider>
    )
}
