import {PropsWithChildren, useCallback, useState} from "react";
import {MenuContext, MenuContextProps} from "../context/MenuContext";


export const MenuProvider = ({children}: PropsWithChildren<{}>) => {
    const [open, setOpen] = useState<boolean>(false)
    const [openConverstionList, setOpenConversationList] = useState<boolean>(false)
    
    const toggleConversationList = useCallback(() => {
        setOpenConversationList((open) => !open)
    }, [])
    
    const closeConversationList = useCallback(() => {
        setOpenConversationList(false)
    }, [])
    
    const openMenu = useCallback(() => {
        setOpen(true)
    }, [])
    
    const closeMenu = useCallback(() => {
        setOpen(false)
    }, [])
    
    const toggleMenu = useCallback(() => {
        setOpen((open) => !open)
    }, [])
    
    const context = {
        open,
        openMenu,
        closeMenu,
        toggleMenu,
        toggleConversationList,
        closeConversationList,
        conversationListOpened : openConverstionList
    } as MenuContextProps
    
    return (
        <MenuContext.Provider value={context}>
            {children}
        </MenuContext.Provider>
    )
}
