import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {GetItemQueryArgs, GetItemsQueryArgs, IJsonResourceResponse} from "../../../shared/types";
import {IConversation} from "../entities";


export const conversationApi = createApi({
    reducerPath: 'conversationApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Conversations',],
    endpoints: (builder) => (
        {
            getConversations: builder.query<IJsonResourceResponse<IConversation, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/conversations?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                    ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Conversations' as const,
                                    id,
                                }
                            )),
                            {type: 'Conversations', id: 'LIST'},
                        ]
                    : [{type: 'Conversations', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IConversation, 'multiple'>) => results,
            }),
            getConversation: builder.query<IConversation, GetItemQueryArgs>({
                query: ({id}) => {
                    return {
                        url: `/conversations/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Conversations', id}],
                transformResponse: (result: IJsonResourceResponse<IConversation, 'single'>) => result.item!
            }),
        }
    ),
});

export const {
    useGetConversationsQuery,
    useGetConversationQuery,
} = conversationApi;
