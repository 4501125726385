import {useState} from "react";

function useError(violations: any) {
    const [errors, setErrors] = useState<any>(violations)

    const hasError = (field: string) => {
        if (undefined === errors) {
            return false
        }
        return field in errors;
    }

    const getError = (field: string): string | null => {
        if (hasError(field)) {
            if (errors[field] instanceof Array) {
                // join all errors into a string with a line break between each error
                return errors[field].join('\n');
            }
            return errors![field].message
        }
        return null
    }

    return {
        hasError,
        getError,
        setErrors,
    }
}

export default useError;